import { classNames } from '../utils/helper'

export const ButtonVariants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    WHITE: 'white',
    BORDER: 'border',
    GHOST: 'ghost',
    LINK: 'link',
}

const BUTTON_STYLES = {
    [ButtonVariants.PRIMARY]: `bg-gradient-to-b from-[#00FFC2] to-[#00cc9a] hover:from-[#00e3ad] hover:to-[#00ab80] disabled:from-neutral-900 disabled:to-neutral-900 font-medium tracking-wide disabled:text-neutral-400 px-5 text-primary-darker font-mono py-3 rounded-[0.40rem] text-[11.5px] uppercase duration-100 flex justify-center gap-3 transition-colors duration-[50ms]`,
    [ButtonVariants.SECONDARY]: `px-5 text-grey-400 font-mono py-3 rounded-[0.40rem] text-[11.5px] tracking-wide uppercase duration-100 flex justify-center gap-3 bg-neutral-600 hover:bg-neutral-200 text-white hover:text-black transition-colors duration-[50ms]`,
    [ButtonVariants.WHITE]: `bg-grey-100 hover:bg-white px-5 text-grey-400 font-mono py-2.5 rounded-[0.40rem] text-[11.5px] tracking-wide uppercase duration-100 flex justify-center items-center gap-3 transition-colors duration-[50ms]`,
    [ButtonVariants.BORDER]: `bg-white/10 backdrop-blur-sm hover:bg-white/15 border border-white/10 px-5 text-white font-mono py-2.5 rounded-lg text-[11.5px] tracking-wide uppercase duration-100 flex justify-center gap-3 transition-colors duration-[50ms]`,
    [ButtonVariants.GHOST]: `bg-none hover:bg-white/10 px-5 text-white font-mono py-3 rounded-[0.40rem] text-[11.5px] tracking-wide uppercase duration-150 flex justify-center gap-3 transition-colors duration-[50ms]`,
    [ButtonVariants.LINK]: `bg-none hover:underline text-white font-mono rounded-[0.40rem] text-[11.5px] tracking-wide uppercase duration-150 flex justify-center gap-3 transition-colors duration-[50ms]`,
}

const baseClass = 'flex items-center gap-2'

const Button = ({ children, ...props }) => {
    const { className, disabled, variant = ButtonVariants.PRIMARY } = props
    return (
        <button
            {...props}
            className={classNames(
                baseClass,
                disabled ? 'opacity-50 pointer-events-none' : 'opacity-100',
                BUTTON_STYLES[variant],
                className
            )}
        >
            {children}
        </button>
    )
}

export default Button
