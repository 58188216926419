import React from 'react'
import TooltipContainer from './../../TooltipContainer'

export default function QuestPlatformStats({ user }) {
    if (!user) return <></>
    return (
        <div className="w-full">
            <StatRow
                statTitle={'TOTAL POINTS COLLECTED'}
                stat={user.totalPointsCollected}
            />
            <TooltipContainer message="Some tasks cannot be verified instantly. Their XP will be held in escrow for up to 24 hours.">
                <StatRow
                    statTitle={'TOTAL POINTS IN ESCROW'}
                    stat={user.totalPointsInEscrow}
                />
            </TooltipContainer>
            <StatRow
                statTitle={'TOTAL POINTS AVAILABLE'}
                stat={user.totalPointsAvailable}
            />
            <StatRow
                statTitle={'TOTAL $RFRM IN VAULT'}
                stat={user.totalRFRMInVault}
            />
        </div>
    )
}

function StatRow({ statTitle, stat }) {
    return (
        <div className="w-full border-t border-[#5e5e5e94] pt-3 pb-4 flex justify-between items-center text-neutral-300/70">
            <p className="text-xs">{statTitle || 'STAT'}</p>
            <p className="text-sm">{stat || 0}</p>
        </div>
    )
}
