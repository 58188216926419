import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../utils/hooks/auth'
import Profile from '../../account/Profile'
import Button from '../../Button'

export default function QuestPlatformUser({
    questPlatformUser,
    profileSettingsOpen,
    handleToggleProfileSettingsOpen,
}) {
    const { t } = useTranslation()
    const { login } = useAuth()
    const { address, isConnected } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()
    const location = useLocation()

    useEffect(() => {
        if (!!address) {
            login.mutate({ address: address })
        }
    }, [address])

    let route = location.pathname

    return (
        <div
            className={`flex flex-col justify-between gap-8 items-center px-[2rem] lg:pr-[4rem] pb-4 h-full overflow-hidden`}
        >
            {isConnected ? (
                <>
                    <div className="w-full">
                        <Profile
                            username={
                                questPlatformUser && questPlatformUser.username
                            }
                        />
                    </div>
                </>
            ) : (
                <div className="items-center justify-center flex w-full h-full flex-1 flex-col min-h-[150px]">
                    <h5 className="text-input-lg mb-6 text-white">
                        {t('connect_wallet')}
                    </h5>
                    <Button
                        className="bg-primary hover:bg-primary hover:brightness-90"
                        onClick={open}
                    >
                        {t('connect_button')}
                    </Button>
                </div>
            )}
        </div>
    )
}
