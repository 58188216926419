import { useEffect, useState } from 'react'
import { HiBolt, HiBoltSlash } from 'react-icons/hi2'
import {
    LuChevronDown,
    LuChevronUp,
    LuClipboardType,
    LuClock,
    LuSearch,
    LuShieldCheck,
} from 'react-icons/lu'
import Button from '../../Button'
import Card from '../../Card'
import Input from '../../Input'
import TooltipContainer from '../../TooltipContainer'

export default function SuperadminTaksFilter({ activeMode, setActiveMode }) {
    let message = getActiveMessage(activeMode)

    function handleToggleActiveMode() {
        switch (activeMode) {
            case 'active':
                setActiveMode('inactive')
                break
            case 'inactive':
                setActiveMode('all')
                break

            default:
                setActiveMode('active')

                break
        }
    }

    return (
        <Card>
            <div className="grid grid-cols-[auto_1fr_auto_auto] gap-5 items-center">
                <div className="-mr-3">
                    <TooltipContainer message={message}>
                        <Button
                            variant="ghost"
                            className={`!px-2.5  ${
                                activeMode === 'active' ? '!text-primary' : ''
                            }`}
                            onClick={handleToggleActiveMode}
                        >
                            {(activeMode === 'active' ||
                                activeMode === 'all') && <HiBolt />}
                            {activeMode === 'inactive' && <HiBoltSlash />}
                        </Button>
                    </TooltipContainer>
                </div>
                {/* <div className="">
                    <Input
                        onChange={handleOnSearchChange}
                        variant="borderinput"
                        className={'!py-1 !px-2'}
                        placeholder={
                            'Search, like tweet URL (only the visible rows)'
                        }
                    >
                        <LuSearch className="text-primary ml-0.5 -mr-1" />
                    </Input>
                </div> */}
                {/* <div className="flex items-center gap-0">
                    <SortOption
                        toggleSort={toggleSort}
                        current_sortMethods={sortMethods}
                        sortMethod={'completions'}
                        explainer={'Sort number of completions'}
                    >
                        <LuShieldCheck />
                    </SortOption>
                    <SortOption
                        toggleSort={toggleSort}
                        current_sortMethods={sortMethods}
                        sortMethod={'totalXPCollected'}
                        explainer={'Sort total xp collected'}
                    >
                        <p className="text-xs font-semibold">XP</p>
                    </SortOption>
                    <SortOption
                        toggleSort={toggleSort}
                        current_sortMethods={sortMethods}
                        sortMethod={'taskURLs'}
                        explainer={'Sort task type'}
                    >
                        <LuClipboardType />
                    </SortOption>
                    <SortOption
                        toggleSort={toggleSort}
                        current_sortMethods={sortMethods}
                        sortMethod={'updatedAt'}
                        explainer={'Sort created (updated) date'}
                    >
                        <LuClock />
                    </SortOption>
                </div>
                <div className="">
                    <MaxSortAmountSelect
                        maxSortAmount={maxSortAmount}
                        setMaxSortAmount={setMaxSortAmount}
                        max={4}
                    />
                </div> */}
            </div>
        </Card>
    )
}

function SortOption({
    toggleSort,
    current_sortMethods,
    sortMethod,
    explainer,
    children,
}) {
    const current_sortMethod = current_sortMethods?.find(
        (sM) => sM.id === sortMethod
    )

    const isSelectedSortMethod = !!current_sortMethod
    return (
        <TooltipContainer message={explainer}>
            <Button
                variant="ghost"
                className={`!px-2 !py-1 !gap-2.5  ${
                    isSelectedSortMethod ? '!text-primary' : ''
                }`}
                onClick={() => toggleSort(sortMethod)}
            >
                <span className="!text-sm">{children}</span>
                <div className="flex flex-col items-center">
                    <LuChevronUp
                        className={
                            isSelectedSortMethod &&
                            current_sortMethod?.type === 'asc'
                                ? '!text-primary'
                                : 'text-neutral-500'
                        }
                    />
                    <LuChevronDown
                        className={
                            isSelectedSortMethod &&
                            current_sortMethod?.type === 'desc'
                                ? '!text-primary'
                                : 'text-neutral-500'
                        }
                    />
                </div>
            </Button>
        </TooltipContainer>
    )
}

function MaxSortAmountSelect({ maxSortAmount, setMaxSortAmount, max }) {
    function handleIncreaseMSA() {
        let increased_maxSortAmount = maxSortAmount + 1
        if (increased_maxSortAmount <= max) {
            return setMaxSortAmount(increased_maxSortAmount)
        }
        return setMaxSortAmount(1)
    }

    let popupPosition = window?.innerWidth < 900 ? 'left' : 'top'

    return (
        <div className="-ml-3 pl-3 border-l border-neutral-600">
            <TooltipContainer
                message={
                    'The maximum amount of sorting options active at the same time.'
                }
                position={popupPosition}
            >
                <Button
                    variant="ghost"
                    className="!px-2 !py-1"
                    onClick={handleIncreaseMSA}
                >
                    {maxSortAmount}
                </Button>
            </TooltipContainer>
        </div>
    )
}

function getActiveMessage(activeMode) {
    switch (activeMode) {
        case 'active':
            return 'Showing active tasks only'
        case 'inactive':
            return 'Showing inactive tasks only'

        default:
            return 'Showing all tasks'
    }
}
