import React, { useEffect, useState } from 'react'
import { LuCheck, LuCopy, LuCopyCheck, LuX } from 'react-icons/lu'
import Button from './Button'
import { classNames } from '../utils/helper'

export default function CopyToClipboard({ className, text }) {
    const [copied, setCopied] = useState(null)

    function handleCopy() {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopied(true)
            })
            .catch((err) => {
                setCopied(false)
            })
    }

    useEffect(() => {
        if (copied === null) return

        const delayTimer = setTimeout(() => {
            setCopied(null)
        }, 3000)

        // Clean up the timer if the component unmounts before the timeout completes
        return () => clearTimeout(delayTimer)
    }, [copied])

    return (
        <Button
            variant="ghost"
            className={classNames('!px-2 !py-2', className)}
            onClick={handleCopy}
        >
            {copied === null ? (
                <LuCopy className="w-[20px] h-[20px]" />
            ) : copied === true ? (
                <LuCheck className="w-[20px] h-[20px] pop-in text-primary" />
            ) : (
                <LuX className="w-[20px] h-[20px] shake-in text-red-600" />
            )}
        </Button>
    )
}
