import { useEffect, useState } from 'react'
import { classNames } from '../utils/helper'

export default function FlyInOutComponent({ isVisible, className, children }) {
    const [isFlyingOut, setIsFlyingOut] = useState(null)
    const [isUnmounted, setIsUnmounted] = useState(null)

    useEffect(() => {
        //on change of the toggle

        //if the toggle makes the popup open
        if (isVisible) {
            setIsUnmounted(null)
            setIsFlyingOut(null)
        }
    }, [isVisible])

    const handleAnimationEnd = () => {
        //if flying out was active
        if (isFlyingOut) {
            setIsUnmounted(true)
            setIsFlyingOut(null)
        }
    }
    const handleAnimationStart = () => {
        //if the aniation is to fly out
        //in other words: if isVisible is false
        if (!isVisible) {
            setIsFlyingOut(true)
        }
    }

    if (isUnmounted) return <></>

    return (
        <div
            className={classNames(
                className,
                `${isVisible ? ' fly-in-from-bottom' : 'fly-out-to-bottom'}`
            )}
            onAnimationStart={handleAnimationStart}
            onAnimationEnd={handleAnimationEnd}
        >
            {children}
        </div>
    )
}
