import React, { useEffect, useState } from 'react'
import { useQuestplatformUser } from '../../../utils/hooks/useQuestplatformUser'
import {
    GET_SUPERADMIN_CONVERSION_RATES_URL,
    STORE_IN_VAULT_URL,
} from '../../../library/urls'
import Loader from '../../Loader'
import Card from '../../Card'
import Button from '../../Button'
import TooltipContainer from '../../TooltipContainer'
import { confirmAction, Request } from '../../../library/functions'
import Swal from 'sweetalert2'

export default function QuestPlatformVaultBody() {
    const { questplatformUser } = useQuestplatformUser()
    const [conversionRates, setConversionRates] = useState(null)

    useEffect(() => {
        //on initial load

        getConversionRates(setConversionRates)
    }, [])

    if (!conversionRates || !questplatformUser)
        return (
            <div className="w-full flex justify-center items-center">
                <Loader />
            </div>
        )

    return (
        <div className="w-full flex justify-center items-center">
            {conversionRates.map((conversionRate) => {
                return (
                    <TokenCard
                        conversionRate={conversionRate}
                        key={conversionRate.token}
                        questplatformUser={questplatformUser}
                    />
                )
            })}
        </div>
    )
}

function TokenCard({ conversionRate, questplatformUser }) {
    if (!conversionRate?.token) return <></>
    if (!conversionRate?.token_multiplier) return <></> //also when 0

    function handleClaim() {
        Swal.fire({ title: 'Coming Soon', heightAuto: true })
    }

    return (
        <Card
            variant="glass"
            className="lg:grid grid-cols-[auto_1fr_auto] flex flex-col lg:gap-8 gap-3 items-start max-w-[700px] lg:p-7"
        >
            <div className="w-[80px] overflow-hidden rounded-lg">
                <img
                    src={conversionRate.image}
                    alt={`${conversionRate.token}_logo`}
                    className="w-full"
                />
            </div>
            <div className="font-mono">
                <h3 className="text-white text-xl">
                    {conversionRate.token || conversionRate.title}
                </h3>
                <p className="text-white/80 text-sm mt-2 font-sans">
                    {conversionRate.description}
                </p>
                <p className="text-primary mt-5">
                    STORED: {questplatformUser.totalRFRMInVault.toFixed(2)}{' '}
                    {conversionRate.token}
                </p>
            </div>
            <div className="h-full flex items-end">
                <TooltipContainer message="Coming soon">
                    <Button disabled={true} onClick={handleClaim}>
                        CLAIM
                    </Button>
                </TooltipContainer>
            </div>
        </Card>
    )
}

async function getConversionRates(setConversionRates) {
    const config = {
        url: GET_SUPERADMIN_CONVERSION_RATES_URL,
        method: 'get',
        data: {},
    }

    Request(config)
        .then((response) => {
            const conversionRates = response?.conversionRates
            if (!conversionRates) return
            setConversionRates(conversionRates)
        })
        .catch((err) => {
            console.log(err)
        })
}
