import Tier from '../tier/Tier'
import Profile from './Profile'
import News from '../news/News'
import Button from '../Button'
import { memo, useEffect } from 'react'
import { useAuth } from '../../utils/hooks/auth'
import { useLocation } from 'react-router-dom'
import Reports from '../news/Reports'
import { useTranslation } from 'react-i18next'
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'

const Account = ({ className }) => {
    const { t } = useTranslation()
    const { login } = useAuth()
    const { address, isConnected } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()
    const location = useLocation()

    // useEffect(() => {
    //     if (userContainer.current) {
    //         gsap.fromTo([userContainer?.current, userContainer?.current], {alpha: 0}, {alpha: 1, duration: 0.4, ease: 'power3.in'})
    //     }
    // }, [user])

    useEffect(() => {
        if (!!address) {
            login.mutate({ address: address })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    return (
        <div
            className={`w-full flex flex-1 flex-col items-start lg:pl-2 lg:pb-2 bg-grey-500 sm:overflow-auto text-white justify-between ${className}`}
        >
            {isConnected ? (
                <>
                    <div className="w-full h-full flex flex-col flex-1 items-center justify-around">
                        <div className="sm:py-12 py-24">
                            <Profile />
                        </div>
                        <Tier />
                    </div>
                </>
            ) : (
                <div className="items-center justify-center flex w-full h-full flex-1 flex-col min-h-[20rem]">
                    <h5 className="text-input-lg mb-6 ">
                        {t('connect_wallet')}
                    </h5>
                    <Button
                        className="bg-primary hover:bg-primary hover:brightness-90"
                        onClick={open}
                    >
                        {t('connect_button')}
                    </Button>
                </div>
            )}
            <div className="w-full h-full flex-col md:flex hidden">
                {location.pathname === '/trading' ? <Reports /> : <News />}
            </div>
        </div>
    )
}

export default memo(Account)
