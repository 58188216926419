import React, { useEffect, useState } from 'react'

import { RenderSVG } from '../../../library/globalComponents'
import { useSelectedTaskCategoryState } from './../../../library/store/selectedTaskGategoryStore'
import Card from './../../Card'
import Loader from './../../Loader'
import allTasksIcon from './../../../assets/svg/icons/all_tasks.svg'

let hiddenCategories = ['medium', 'youtube']

export default function SelectTasks({
    taskCategories,
    containerHeight = 230,
    useShadow = true,
    twitterBlueFilter,
    setTwitterBlueFilter,
    showWheelTab,
    setShowWheelTab,
    user,
}) {
    const { selectedTaskCategory, setSelectedTaskCategory } =
        useSelectedTaskCategoryState()

    if (taskCategories === null)
        return (
            <div className={'p-4'}>
                <Loader />
            </div>
        )
    if (taskCategories.length === 0)
        return (
            <div className={'p-4'}>
                <p className="text-white">No Categories found</p>
            </div>
        )

    const showTwitterBlueTab = !!user?.communityNames?.includes('Twitter Blue')

    if (!showTwitterBlueTab) {
        //allow one more hidden category to show
        hiddenCategories = ['medium']
    }

    const filtered_taskCategories = taskCategories.filter(
        (taskCategory) => !hiddenCategories.includes(taskCategory.id)
    )

    return (
        <div
            className={`w-full max-h-[200px] sticky top-3 z-10 ${
                useShadow
                    ? 'shadow-[0px_0px_50px_20px_rgba(32,32,32,1)] bg-[#202020]'
                    : ''
            }`}
            style={{
                height: `${containerHeight}px`,
            }}
        >
            <div className="flex gap-4 h-full overflow-x-auto w-full">
                {filtered_taskCategories.map((category, index) => {
                    let isSelected = category?.id === selectedTaskCategory?.id

                    function handleSelectCat(category) {
                        if (isSelected) {
                            setSelectedTaskCategory(null)
                        } else {
                            setSelectedTaskCategory(category)
                        }
                        setShowWheelTab(false)
                        setTwitterBlueFilter(false)
                    }

                    return (
                        <TaskCategoryCard
                            category={category}
                            index={index}
                            key={category.id}
                            isSelected={isSelected}
                            setSelectedTaskCategory={handleSelectCat}
                        />
                    )
                })}
                {showTwitterBlueTab && (
                    <TaskCategoryCard
                        category={{
                            id: 'twitter-blue',
                            icon: '/assets/icons/twitterIcon.svg',
                            name: 'Twitter Blue',
                        }}
                        index={taskCategories.length}
                        key={'twitter-blue'}
                        isSelected={twitterBlueFilter}
                        setSelectedTaskCategory={() => {
                            if (setTwitterBlueFilter) {
                                setTwitterBlueFilter(!twitterBlueFilter)
                                setSelectedTaskCategory(null)
                            }
                            setShowWheelTab(false)
                        }}
                    />
                )}

                <TaskCategoryCard
                    category={{
                        id: 'all_tasks',
                        icon: allTasksIcon,
                        name: 'All',
                    }}
                    index={taskCategories.length}
                    key={'all_tasks'}
                    isSelected={
                        selectedTaskCategory === null && !twitterBlueFilter
                    }
                    setSelectedTaskCategory={() => {
                        setSelectedTaskCategory(null)
                        setTwitterBlueFilter(null)
                    }}
                />
            </div>
        </div>
    )
}

function TaskCategoryCard({
    category,
    isSelected,
    index,
    setSelectedTaskCategory,
    isATask = true,
}) {
    let isInverted = index % 2 === 0

    let isMobile = window.innerWidth <= 800

    return (
        <div
            className={`cursor-pointer from-[#00FFC2] to-[#00ffc302] p-[2px] rounded-[6px] min-h-[130px] min-w-[150px] h-full w-full ${
                isInverted ? 'bg-gradient-to-b' : 'bg-gradient-to-t'
            }`}
            onClick={() => setSelectedTaskCategory(category)}
        >
            <div
                className={`w-full h-full  rounded-[3px] flex flex-col gap-4 items-center justify-center p-4  ${
                    isSelected
                        ? ` ${
                              isInverted
                                  ? 'bg-gradient-to-b'
                                  : 'bg-gradient-to-t'
                          } from-[#2d2f2f] to-[#202020]`
                        : 'bg-[#202020]'
                }
                    
                `}
            >
                <RenderSVG
                    src={category.icon}
                    id={category.id}
                    color={isSelected ? '#00FFC2' : 'white'}
                    hoverColor={isSelected ? '#29ffcc' : '#f0f0f0'}
                    width={isMobile ? 30 : 40}
                    height={isMobile ? 30 : 40}
                />
                <p
                    className={`text-center  text-[13px] ${
                        isSelected ? 'text-primary/70' : 'text-neutral-400'
                    }`}
                >
                    {category.name?.toUpperCase()}
                    <br />
                    {isATask ? 'TASKS' : ''}
                </p>
            </div>
        </div>
    )
}
