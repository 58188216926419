import React, { useEffect, useState } from 'react'
import { useQuestplatformUser } from '../../../utils/hooks/useQuestplatformUser'
import TooltipContainer from '../../../components/TooltipContainer'

import {
    GET_SUPERADMIN_CONVERSION_RATES_URL,
    STORE_IN_VAULT_URL,
} from '../../../library/urls'
import Loader from '../../Loader'
import Card from '../../Card'
import Button from '../../Button'
import { confirmAction, Request } from '../../../library/functions'
import Swal from 'sweetalert2'

export default function QuestPlatformShopBody() {
    const { questplatformUser } = useQuestplatformUser()
    const [conversionRates, setConversionRates] = useState(null)

    useEffect(() => {
        //on initial load

        getConversionRates(setConversionRates)
    }, [])

    if (!conversionRates || !questplatformUser)
        return (
            <div className="w-full flex justify-center items-center">
                <Loader />
            </div>
        )

    return (
        <div className="w-full flex justify-center items-center">
            {conversionRates.map((conversionRate) => {
                return (
                    <TokenCard
                        conversionRate={conversionRate}
                        key={conversionRate.token}
                        questplatformUser={questplatformUser}
                    />
                )
            })}
        </div>
    )
}

function TokenCard({ conversionRate, questplatformUser }) {
    if (!conversionRate?.token) return <></>
    if (!conversionRate?.XP_to_token_multiplier) return <></> //also when 0

    let tokenAmount =
        questplatformUser.totalPointsAvailable *
        conversionRate.XP_to_token_multiplier
    let hasEnoughXP = tokenAmount >= conversionRate.minimum_claim_tokens
    if (tokenAmount === 0) hasEnoughXP = false

    function handleClaim() {
        confirmAction(
            claimTokens,
            { conversionRate, questplatformUser },
            `Claiming will convert all XP into ${conversionRate.token} and store it in the vault. This cannot be reverted. Are you sure you want to convert your XP to RFRM?`,
            'question'
        )
    }

    return (
        <Card
            variant="glass"
            className="lg:grid grid-cols-[auto_1fr_auto] flex flex-col lg:gap-8 gap-3 items-start max-w-[700px] lg:p-7"
        >
            <div className="w-[80px] overflow-hidden rounded-lg">
                <img
                    src={conversionRate.image}
                    alt={`${conversionRate.token}_logo`}
                    className="w-full"
                />
            </div>
            <div className="font-mono">
                <h3 className="text-white text-xl">
                    {conversionRate.token || conversionRate.title}
                </h3>
                <p className="text-white/80 text-sm mt-2">
                    {conversionRate.description}
                </p>
                <p className="text-primary mt-5">
                    AMOUNT: {tokenAmount.toFixed(2)} {conversionRate.token}
                </p>
            </div>
            <div className="h-full flex items-end">
                <TooltipContainer message={'Coming Soon'}>
                    <Button disabled={true}>CLAIM</Button>
                </TooltipContainer>
            </div>
        </Card>
    )
}

async function getConversionRates(setConversionRates) {
    const config = {
        url: GET_SUPERADMIN_CONVERSION_RATES_URL,
        method: 'get',
        data: {},
    }

    Request(config)
        .then((response) => {
            const conversionRates = response?.conversionRates
            if (!conversionRates) return
            setConversionRates(conversionRates)
        })
        .catch((err) => {
            console.log(err)
        })
}

function claimTokens({ conversionRate, questplatformUser }) {
    if (!conversionRate || !questplatformUser)
        return Swal.fire({
            title: 'Invalid Input',
            text: 'The request was not successful',
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            showCancelButton: false,
            allowOutsideClick: false,
            heightAuto: false,
        })

    const config = {
        url: STORE_IN_VAULT_URL,
        method: 'post',
        data: {
            wallet: questplatformUser.wallet,
            XP_amount: questplatformUser.totalPointsAvailable,
            token_type: conversionRate.token,
        },
    }

    let tokenAmount =
        questplatformUser.totalPointsAvailable *
        conversionRate.XP_to_token_multiplier

    Request(config)
        .then((response) => {
            if (response?.success) {
                Swal.fire({
                    title: 'Success!',
                    text: `Successfully stored ${tokenAmount}${conversionRate.token} in the vault`,
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.reload()
                })
            } else {
                Swal.fire({
                    title: 'Invalid Input',
                    text: 'The request was not successful',
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'The request was not successful',
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            })
        })
}
