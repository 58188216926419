import React, { useEffect, useState } from 'react'
import Card from '../../Card'
import Loader from '../../Loader'
import { LuAlertTriangle } from 'react-icons/lu'
import Button from '../../Button'
import Swal from 'sweetalert2'
import { includesAny, Request, swalError } from '../../../library/functions'
import {
    CREATE_COMMUNITY_URL,
    DELETE_COMMUNITY_URL,
    GET_COMMUNITIES_URL,
    GET_COMMUNITY_TASKS_URL,
    GET_REFERRALLINK_REFERRALSTATS_URL,
    UPDATE_COMMUNITY_URL,
} from '../../../library/urls'
import Input from '../../Input'
import { SelectSeparator } from '../../shadcnui/ui/select'
import SelectItem from './../../form/SelectItem'
import CountUp from 'react-countup'
import { getEthereumSignature } from '../../../library/signature'

export default function CommunitiesDashboard() {
    const [communities, setCommunities] = useState(null)
    const [warningSeen, setWarningSeen] = useState(
        sessionStorage.getItem('reformdao-warning-seen-communities')
    )

    useEffect(() => {
        //on initial load
        getCommunities(setCommunities)
    }, [])

    if (communities === null)
        return (
            <Card className={'h-full'}>
                <Loader message={'Loading communities'} />
            </Card>
        )

    if (!warningSeen) return <WarningCard setWarningSeen={setWarningSeen} />

    return (
        <Card className={'h-full !p-5'}>
            <CommunityExplainer />
            <CommunitiesList communities={communities} />
            <AddCommunityContainer />
        </Card>
    )
}

function CommunityExplainer() {
    return (
        <div className="mb-6">
            <p className="text-white ">Communities</p>
            <p className="text-neutral-500 text-sm mt-1.5">
                Communities are used to display certain tasks for specific
                users. Add, edit and delete communities below
            </p>
        </div>
    )
}

function WarningCard({ setWarningSeen }) {
    return (
        <Card className={'h-full flex items-center justify-center '}>
            <div className="max-w-[500px] gap-3 flex flex-col items-center">
                <LuAlertTriangle className="text-orange-500 text-3xl mb-3" />
                <p className="-mb-1 text-white font-bold text-lg">Caution</p>
                <p className="text-neutral-400 text-center text-pretty">
                    Be extremely careful asjusting the communities. The changes
                    you make impact the user experience directly and cannot be
                    reversed.
                    <br />
                    <br />
                    You can continue. Nothing happens still.
                </p>
                <div className="flex items-center justify-center mt-8">
                    <Button
                        variant="primary"
                        onClick={() => {
                            sessionStorage.setItem(
                                'reformdao-warning-seen-communities',
                                'seen'
                            )
                            setWarningSeen('seen')
                        }}
                    >
                        I understand, continue
                    </Button>
                </div>
            </div>
        </Card>
    )
}

function CommunitiesList({ communities }) {
    if (!communities?.length) return null

    return (
        <div className="flex flex-col gap-2">
            {communities.map((community) => {
                return (
                    <CommunityCard community={community} key={community.name} />
                )
            })}
        </div>
    )
}

function AddCommunityContainer() {
    const [communityName, setCommunityName] = useState(null)

    function handleCreate() {
        if (!communityName) return
        confirmAction(createCommunity, { name: communityName })
    }

    return (
        <div className="mt-6">
            <p className="text-neutral-200 text-md">Add new community</p>
            <SelectSeparator className="w-full bg-neutral-700 mb-4 mt-2" />
            <div className="flex items-end justify-between gap-3">
                <div className="w-full -mb-[1px]">
                    <p className="text-neutral-400 text-sm mb-0.5">
                        Community Name
                    </p>
                    <Input
                        placeholder={'Community Name'}
                        value={communityName || ''}
                        onChange={(e) => setCommunityName(e.target.value)}
                    ></Input>
                </div>
                <Button onClick={handleCreate}>Create</Button>
            </div>
        </div>
    )
}

const joinMethodOptions = [
    { label: ' Instantly upon opening the referral link', value: 'Instantly' },
    { label: 'Upon completing any task in the platform', value: 'AnyTask' },
    {
        label: 'Upon completing a specific task, which will be the only visible task until completion',
        value: 'SpecificTask_RefLink',
    },
    {
        label: 'Upon completing a specific task, which is visible for all users',
        value: 'SpecificTask_All',
    },
    {
        label: 'Upon completing a specific task, which is visible for no users',
        value: 'SpecificTask_None',
    },
]

function CommunityCard({ community }) {
    const [expanded, setExpanded] = useState(false)
    const [communityObj, setCommunityObj] = useState(community)
    const [taskOptions, setTaskOptions] = useState(null)

    useEffect(() => {
        if (
            includesAny(communityObj.joinMethod, [
                'SpecificTask_RefLink',
                'SpecificTask_All',
                'SpecificTask_None',
            ]) &&
            taskOptions === null
        ) {
            getTaskOptions(community.name, setTaskOptions)
        }
    }, [communityObj.joinMethod])

    function changeCommunityObj(key, value) {
        let new_communityObj = { ...communityObj }
        new_communityObj[key] = value
        setCommunityObj(new_communityObj)
    }

    function handleSave() {
        confirmAction(updateCommunity, { updateParams: { ...communityObj } })
    }

    function handleDelete() {
        confirmAction(deleteCommunity, {
            communityId: community.id,
            confirmActionmessage: `Are you sure you want to delete '${community.name}'`,
        })
    }

    return (
        <div className="">
            <div
                className={`border border-white/20 bg-neutral-800 hover:bg-neutral-400/20 cursor-pointer text-white ${
                    expanded ? 'rounded-t-md ' : 'rounded-md '
                }`}
                onClick={() => setExpanded(!expanded)}
            >
                <div className="flex items-center justify-between px-4  py-2">
                    <div className="text-sm text-neutral-300">
                        {community.name}
                    </div>{' '}
                    <div className="text-xs text-neutral-300">
                        {expanded ? 'Collapse' : 'Expand'}
                    </div>
                </div>
            </div>
            <div className="">
                {' '}
                {expanded && (
                    <div
                        className="bg-neutral-800/50 px-4 pt-2 pb-4 flex flex-col gap-2 border-white/20 border border-t-0 rounded-b-md"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="">
                            <p className="text-neutral-400 text-sm mb-1">
                                Name{' '}
                                <span className="text-xs text-neutral-600  duration-100 ml-2">
                                    Caution: changing the name is a heavy
                                    opperation
                                </span>
                            </p>
                            <Input
                                value={communityObj.name}
                                onChange={(e) =>
                                    changeCommunityObj('name', e.target.value)
                                }
                                name="name"
                                key="name"
                                id="name"
                            ></Input>
                        </div>
                        <div className=" grid-cols-2 md:grid flex flex-col gap-2.5">
                            <div className="">
                                <p className="text-neutral-400 text-sm mb-1">
                                    Join Method
                                </p>
                                <SelectItem
                                    value={communityObj.joinMethod}
                                    options={joinMethodOptions}
                                    setSelected={(value) =>
                                        changeCommunityObj('joinMethod', value)
                                    }
                                    id="joinMethod"
                                    className="!w-full"
                                ></SelectItem>
                            </div>
                            <div
                                className={
                                    includesAny(communityObj.joinMethod, [
                                        'SpecificTask_RefLink',
                                        'SpecificTask_All',
                                        'SpecificTask_None',
                                    ])
                                        ? ''
                                        : 'opacity-20'
                                }
                            >
                                <p className="text-neutral-400 text-sm mb-1">
                                    Task to be completed
                                </p>
                                <SelectItem
                                    value={communityObj.joinTaskId}
                                    options={
                                        taskOptions || [
                                            {
                                                label: 'No community tasks found for this community',
                                                value: null,
                                            },
                                        ]
                                    }
                                    noEmptySelect={!taskOptions}
                                    setSelected={(value) =>
                                        changeCommunityObj('joinTaskId', value)
                                    }
                                    id="joinTaskId"
                                    className="!w-full"
                                ></SelectItem>
                                <a href="/novus-editor">
                                    <Button variant="link" className="-mt-2">
                                        Create the task here
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <SelectSeparator className="bg-neutral-700 mt-3 mb-2" />
                        <div className=" grid-cols-2 md:grid flex flex-col gap-2.5">
                            <div className="">
                                <p className="text-neutral-400 text-sm mb-1">
                                    Referrallink id
                                </p>
                                <Input
                                    prefix={
                                        <p className="text-nowrap text-neutral-400">
                                            https://reformdao.com/novus?referral=
                                        </p>
                                    }
                                    value={communityObj.referralLinkId}
                                    onChange={(e) =>
                                        changeCommunityObj(
                                            'referralLinkId',
                                            e.target.value
                                        )
                                    }
                                    placeholder={'Link Id'}
                                    name="referralLinkId"
                                    key="name"
                                    id="name"
                                ></Input>
                                {!communityObj.referralLinkId ? (
                                    <p className="text-neutral-600 text-xs mt-1.5">
                                        The link gets created upon save.
                                    </p>
                                ) : (
                                    <p className="text-neutral-600 text-xs mt-1.5">
                                        You can safely update the
                                        referralLinkId.
                                        <br />
                                        Caution: This means the previous link
                                        gets invalid (not recognized anymore)
                                    </p>
                                )}
                            </div>
                            <div className="">
                                <ReferralStats
                                    referralLinkId={communityObj.referralLinkId}
                                />
                            </div>
                        </div>
                        <div className="mt-2 w-full flex justify-end gap-2.5">
                            <Button onClick={handleDelete} variant="border">
                                Delete
                            </Button>
                            <Button
                                onClick={handleSave}
                                disabled={
                                    includesAny(communityObj.joinMethod, [
                                        'SpecificTask_RefLink',
                                        'SpecificTask_All',
                                        'SpecificTask_None',
                                    ]) && !communityObj.joinTaskId
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

function ReferralStats({ referralLinkId }) {
    const [referralStats, setReferralStats] = useState(null)

    useEffect(() => {
        //get referral stats
        getReferralStats(referralLinkId, setReferralStats)
    }, [])

    if (!referralLinkId) return

    return (
        <div className="">
            <p className="text-neutral-400 text-sm mb-1">
                Number of users with this link
            </p>
            <p className="text-neutral-400 text-xl mb-1">
                <div className="flex items-center min-h-[44px]">
                    <CountUp
                        start={0}
                        end={referralStats?.total_referredUsers}
                        duration={2.75}
                        useEasing={true}
                        useGrouping={true}
                        separator=","
                    />
                </div>
            </p>
        </div>
    )
}

async function getCommunities(setCommunities) {
    const config = {
        url: GET_COMMUNITIES_URL,
        method: 'get',
        data: {},
    }
    Request(config)
        .then((response) => {
            const communities = response?.communities
            if (!communities) return
            setCommunities(communities)
        })
        .catch((err) => {
            console.log(err)
        })
}

function confirmAction(action, props) {
    Swal.fire({
        title: 'Are you sure?',
        text:
            props.confirmActionmessage ||
            'This action is permanent and cannot be undone',
        icon: 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            action(props)
        }
    })
}

async function updateCommunity({ updateParams }) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: UPDATE_COMMUNITY_URL,
        method: 'post',
        data: {
            updateParams: JSON.stringify(updateParams),
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Successfully updated',
                icon: 'success',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
        })
        .catch((err) => {
            console.log(err)
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
        })
}
async function createCommunity({ name }) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: CREATE_COMMUNITY_URL,
        method: 'post',
        data: {
            communityName: name,
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Successfully created',
                icon: 'success',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
            console.log(err)
        })
}
async function deleteCommunity({ communityId, communityName }) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: DELETE_COMMUNITY_URL,
        method: 'post',
        data: {
            communityId: communityId,
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted',
                icon: 'success',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {
                //hard reload
                window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=communities`
            })
            console.log(err)
        })
}

async function getTaskOptions(communityName, setJoinTaskOptions) {
    const config = {
        url: GET_COMMUNITY_TASKS_URL,
        method: 'get',
        data: { communityName: communityName },
    }

    Request(config)
        .then((response) => {
            const communityTasks = response?.communityTasks

            if (!communityTasks) return
            const communityTaskOptions = communityTasks.map((task) => {
                return { label: task.title, value: task.id }
            })
            setJoinTaskOptions(communityTaskOptions)
        })
        .catch((err) => {
            console.log(err)
        })
}

async function getReferralStats(referralLinkId, setReferralStats) {
    const config = {
        url: GET_REFERRALLINK_REFERRALSTATS_URL,
        method: 'get',
        data: { referralLinkId: referralLinkId },
    }

    Request(config)
        .then((response) => {
            const referralStats = response?.referralStats

            if (!referralStats) return
            setReferralStats(referralStats)
        })
        .catch((err) => {
            console.log(err)
        })
}
