export const questPlatformDeveloperEmail = 'koen@reformdao.com'

export const questPlatform_inMaintenance = false

export const questplatform_storeItems = [{}]

export const taskEndpointOptions_old = [
    {
        value: '',
        label: 'Select...',
        display: 'Unknown Task',
        actionLink: '',
    },
    {
        value: '/twitter/verify-following',
        label: 'Twitter: verify following',
        display: 'Follow',
        actionLink: 'https://x.com/{{usernameToFollow}}',
        availableAdditionalProps: { usernameToFollow: '' },
        XP: 10,
    },
    {
        value: '/twitter/verify-like',
        label: 'Twitter: verify like',
        display: 'Like',
        actionLink: '{{tweetURL}}',
        availableAdditionalProps: { tweetURL: '' },
        XP: 1,
    },
    {
        value: '/twitter/verify-reply',
        label: 'Twitter: verify comment/reply',
        display: 'Reply',
        actionLink: '{{tweetURL}}',
        availableAdditionalProps: { tweetURL: '' },
        XP: 1,
    },
    {
        value: '/twitter/verify-tweet',
        label: 'Twitter: verify tweet',
        display: 'Tweet',
        actionLink: 'https://twitter.com/intent/tweet?text={{word}}',
        availableAdditionalProps: { word: '' },
        XP: 1,
    },
    {
        value: '/twitter/verify-retweet',
        label: 'Twitter: verify retweet',
        display: 'Retweet',
        actionLink: '{{tweetURL}}',
        availableAdditionalProps: { tweetURL: '' },
        XP: 1,
    },
    // {
    //     value: '/telegram/verify-joined',
    //     label: 'Telegram: verify joined channel',
    //     display: 'Join Channel',
    //     actionLink: 'https://telegram.com',
    //     availableAdditionalProps: { channelId: '' },
    //     XP: 1,
    // },
    // {
    //     value: '/medium/verify-following',
    //     label: 'Medium: verify following',
    //     display: 'Follow',
    //     actionLink: 'https://medium.com',
    //     availableAdditionalProps: { userIdToFollow: '' },
    //     XP: 1,
    // },
    // {
    //     value: '/instagram/verify-following',
    //     label: 'Instagram: verify following (COMING SOON)',
    //     display: 'Follow',
    //     actionLink: 'https://instagram.com',
    //     availableAdditionalProps: { usernameToFollow: '' },
    //     XP: 1,
    // },
    // {
    //     value: '/instagram/verify-liked',
    //     label: 'Instagram: verify liked post (COMING SOON)',
    //     display: 'Like',
    //     actionLink: 'https://instagram.com',
    //     availableAdditionalProps: { postId: '' },
    //     XP: 1,
    // },
    // {
    //     value: '/discord/verify-joined',
    //     label: 'Discord: verify joined server (COMING SOON)',
    //     display: 'Join Server',
    //     actionLink: 'https://discord.com',
    //     availableAdditionalProps: { guildId: '' },
    //     XP: 1,
    // },
    // {
    //     value: '/discord/verify-hasrole',
    //     label: 'Discord: verify has role (COMING SOON)',
    //     display: 'Aquire Role',
    //     actionLink: 'https://discord.com',
    //     availableAdditionalProps: {
    //         guildId: '',
    //         roleId: '',
    //     },
    //     XP: 1,
    // },
]
