import { useEffect, useState } from 'react'
import { Request } from './../../../library/functions'
import { GET_TASKENDPOINTOPTIONS_URL } from './../../../library/urls'
import { useTaskEndpointOptionsStore } from '../../../library/store/taskEndpointOptionsStore'

export default function useGetTaskEndpointOptions() {
    const { taskEndpointOptions, setTaskEndpointOptions } =
        useTaskEndpointOptionsStore()

    const [local_taskEndpointOptions, set_local_taskEndpointOptions] =
        useState(null)

    useEffect(() => {
        //on change of the global state: update the local state
        if (!taskEndpointOptions) return
        set_local_taskEndpointOptions(taskEndpointOptions)
    }, [taskEndpointOptions])

    useEffect(() => {
        //initial: check if there is already a global state, else: create
        if (taskEndpointOptions) return
        getTaskEndpointOptions(setTaskEndpointOptions)
    }, [])

    //return the global state
    return { local_taskEndpointOptions }
}

function getTaskEndpointOptions(setTaskEndpointOptions) {
    const config = {
        url: GET_TASKENDPOINTOPTIONS_URL,
        method: 'get',
    }

    Request(config)
        .then((response) => {
            const taskEndpointOptions = response?.taskEndpointOptions
            if (!taskEndpointOptions) return
            setTaskEndpointOptions(taskEndpointOptions)
        })
        .catch((err) => {
            console.log(err)
        })
}
