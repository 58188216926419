import React, { useEffect, useState } from 'react'
import { combineSortFunctions } from '../../../library/functions'
import SelectTasks from '../dashboard/SelectTasks'
import StatCard from './StatCard'
import SuperadminTaksFilter from './SuperadminTaksFilter'
import SuperadminTasks from './SuperadminTasks'

export default function SuperadminDashboard({
    user,
    superadminTasks,
    superadminStats,
    taskCategories,
}) {
    const [filteredSuperAdminTasks, setFilteredSuperadminTasks] = useState(null)
    const [filterFunction, setFilterFunction] = useState(null)
    const [sortFunction, setSortFunction] = useState(null)

    useEffect(() => {
        sortAndFilterTasks(
            superadminTasks,
            filterFunction,
            sortFunction,
            setFilteredSuperadminTasks
        )
    }, [filterFunction, sortFunction, superadminTasks])

    function handleSuperadminTaskFilter(_filter) {
        setFilterFunction({ filterFunction: _filter })
    }
    function handleSuperadminTaskSorts(_sorts) {
        setSortFunction({ sortFunctions: _sorts })
    }

    return (
        <div className="h-full max-h-full gap-3 flex flex-col">
            <div className="lg:grid grid-cols-[1fr_1fr_1fr_1fr] flex flex-col gap-3">
                <StatCard
                    title={'Total users'}
                    stat={superadminStats?.totalUsers}
                />
                <StatCard
                    title={'Total Active Users'}
                    subtitle={
                        'Users that completed a task within the last 24 hours'
                    }
                    stat={superadminStats?.totalActiveUsers}
                />
                <StatCard
                    title={'Total Tasks Completed'}
                    stat={superadminStats?.totalTasksCompleted}
                />
                <StatCard
                    title={'Tasks Completed in the last 24 Hours'}
                    stat={superadminStats?.dailyTasksCompleted}
                />
                <StatCard
                    title={'Collected total'}
                    stat={superadminStats?.xpTotal}
                    suffix={'XP'}
                />
                <StatCard
                    title={'collected in last 24 hours'}
                    stat={superadminStats?.xpDaily}
                    suffix={'XP'}
                />
                <StatCard
                    title={'total average collected per User'}
                    subtitle={'The amount of XP collected on average per user'}
                    stat={superadminStats?.xpAverageUser}
                    suffix={'XP'}
                />
                <StatCard
                    title={'Max Completions Finished'}
                    subtitle={
                        'The amount of tasks that have surpassed the maxCompletion amount'
                    }
                    stat={superadminStats?.totalMaxCompletionSurpassed}
                />
            </div>
            {/* <div className="grid grid-rows-[auto_auto_1fr] h-full gap-3 w-full">
                <SelectTasks
                    taskCategories={taskCategories}
                    containerHeight={160}
                    useShadow={false}
                />
                <SuperadminTaksFilter
                    handleSuperadminTaskFilter={handleSuperadminTaskFilter}
                    handleSuperadminTaskSorts={handleSuperadminTaskSorts}
                />
                <SuperadminTasks tasks={filteredSuperAdminTasks} user={user} />
            </div> */}
        </div>
    )
}

export function sortAndFilterTasks(
    superadminTasks,
    filter,
    sort,
    setFilteredSuperadminTasks
) {
    if (!superadminTasks) return null
    let old_superadminTasks = [...superadminTasks]

    let superadminFilteredTasks = old_superadminTasks
    //filter
    if (filter?.filterFunction) {
        superadminFilteredTasks = old_superadminTasks.filter(
            filter.filterFunction
        )
    }

    //sort
    if (sort?.sortFunctions?.length) {
        const combinedSortFunction = combineSortFunctions(sort?.sortFunctions)
        superadminFilteredTasks.sort(combinedSortFunction)
    }

    setFilteredSuperadminTasks(superadminFilteredTasks)
}
