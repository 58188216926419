import { NavLink } from 'react-router-dom'
import { classNames } from '../../utils/helper'
import { useWeb3 } from '../../utils/hooks/web3'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const Menu = ({ items }) => {
    const { changeNetwork } = useWeb3()
    const { chainId } = useWeb3ModalAccount()
    const { isConnected } = useWeb3ModalAccount()

    return (
        <>
            {
                <div className="w-full md:flex flex-row flex-1 items-center h-full mx-4 hidden">
                    <a
                        href="https://reformdao.com"
                        className="text-gray-200 block px-4 transition-all duration-200 hover:text-primary font-sans text-sm"
                    >
                        Home
                    </a>
                    {items?.map(
                        (item, index) =>
                            !!item.menuItem && (
                                <NavLink
                                    end
                                    key={`${index}-${item?.id}`}
                                    to={item?.path}
                                    className={({ isActive }) => {
                                        return classNames(
                                            isActive
                                                ? 'text-primary'
                                                : 'text-gray-200',
                                            'block px-4 transition-all duration-200 hover:text-primary font-sans text-sm'
                                        )
                                    }}
                                >
                                    {item?.title}
                                </NavLink>
                            )
                    )}
                    {chainId?.toString() !== process.env.REACT_APP_CHAIN_ID &&
                        isConnected && (
                            <div
                                onClick={changeNetwork}
                                className="px-4 py-1 bg-tertiary text-grey-100 ml-4 cursor-pointer"
                            >
                                {'Incorrect network'}
                            </div>
                        )}
                </div>
            }
        </>
    )
}

export default Menu
