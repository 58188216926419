import Modal from '../layout/Modal'
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import SelectItem from '../form/SelectItem'
import FormItem from '../form/FormItem'
import RangeItem from '../form/RangeItem'
import Button from '../Button'
import { useBonding } from '../../utils/hooks/bonding'
import _ from 'lodash'
import Chart from 'react-apexcharts'

const initialFormValues = {
    amount: '',
    token: 'eth',
    input_amount: false, // is false then RFRM amount is input
    lock_period: 5,
}

const paymentMethods = [
    { label: 'USDT', value: 'usdt' },
    { label: 'USDC', value: 'usdc' },
    { label: 'ETH', value: 'eth' },
]

const BondsDialog = ({ onClose, isOpen }) => {
    const chartRef = useRef()
    const { bondsQuery } = useBonding()
    const { t } = useTranslation()
    const { buyBonds, getPrice } = useBonding()
    const [activeField, setActiveField] = useState(null)
    const [inputAmount, setInputAmount] = useState(false)
    const [currency, setCurrency] = useState('eth')
    const [selectedDiscount, setSelectedDiscount] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [discounts, setDiscounts] = useState([]) // Define discounts as a state variable
    const [chartWidth, setChartWidth] = useState(
        window.innerWidth < 1280 ? '330px' : '465px'
    )

    const getChartWidth = () => {
        if (window.innerWidth < 1280) {
            setChartWidth('330px')
        } else {
            setChartWidth('465px')
        }
    }

    useEffect(() => {
        window.addEventListener('resize', getChartWidth)
        return () => {
            window.removeEventListener('resize', getChartWidth)
        }
    }, [])

    useEffect(() => {
        if (!bondsQuery.data) return
        setDiscounts(bondsQuery.data['discounts'])
        // Filter out any non-numeric values
        setDataLoaded(true) // Set dataLoaded to true once data is fetched
    }, [bondsQuery.data])

    useEffect(() => {
        setSelectedDiscount({
            x: discounts[discounts.length - 1]?.lock_period,
            y: discounts[discounts.length - 1]?.discounts / 100,
        })
    }, [discounts])

    const chartConfig = dataLoaded
        ? {
              series: [
                  {
                      name: 'Years',
                      data: discounts.map((d) => d.discount / 100),
                  },
              ],
              options: {
                  chart: {
                      id: 'chart',
                      events: {
                          dataPointSelection: false,
                          selection: false,
                      },
                      selection: {
                          enabled: false,
                      },
                      zoom: {
                          enabled: false,
                      },
                      width: '100%',
                      height: 400,
                      type: 'area',
                      toolbar: {
                          show: false,
                      },
                  },
                  tooltip: {
                      enabled: false,
                  },
                  dataLabels: {
                      enabled: false,
                  },
                  grid: {
                      position: 'back',
                      strokeDashArray: 2,
                      borderColor: '#B2B1AD',
                      xaxis: {
                          lines: {
                              show: false,
                          },
                      },
                      yaxis: {
                          lines: {
                              show: true,
                          },
                      },
                  },
                  fill: {
                      colors: ['#00FFC2'],
                      type: 'gradient',
                      gradient: {
                          shade: 'dark',
                          shadeIntensity: 0.5,
                          opacityFrom: 0.5,
                          opacityTo: 0,
                          stops: [0, 100],
                      },
                  },
                  stroke: {
                      curve: 'straight',
                      colors: ['#00FFC2'],
                      width: 2,
                  },
                  xaxis: {
                      type: 'category',
                      categories: discounts.map((d) => d.lock_period),
                      tickPlacement: 'on',
                      labels: {
                          show: true,
                          style: {
                              colors: '#F3F2EE',
                              fontFamily: 'Space Mono',
                              fontSize: '11px',
                          },
                          offsetY: 5,
                      },
                      style: {
                          color: '#B2B1AD',
                          fontSize: '10px',
                          fontFamily: 'Apercu Mono Pro',
                          fontWeight: 300,
                          cssClass: 'axistitle',
                      },
                      axisBorder: {
                          show: false,
                      },
                      axisTicks: {
                          show: false,
                      },
                      show: true,
                      title: {
                          text: 'years',
                          style: {
                              color: '#B2B1AD',
                              cssClass: 'axistitle',
                          },
                          floating: true,
                          offsetY: 6,
                      },
                  },
                  yaxis: {
                      tickAmount: 'dataPoints',
                      show: true,
                      opposite: false,
                      labels: {
                          show: true,
                          style: {
                              colors: '#F3F2EE',
                              fontFamily: 'Space Mono',
                              fontSize: '11px',
                          },
                          offsetX: -10,
                          formatter: (value) => {
                              return value.toFixed(0) + ' %'
                          },
                      },
                  },
                  annotations: {
                      xaxis: [
                          {
                              x: selectedDiscount?.x,
                              strokeDashArray: 0,
                              fillColor: '#B2B1AD',
                              borderColor: '#B2B1AD',
                              offsetY: 0,
                          },
                      ],
                      points: [
                          {
                              x: selectedDiscount?.x,
                              y: selectedDiscount?.y,
                              marker: {
                                  size: 6,
                                  fillColor: '#fff',
                                  strokeColor: '#00FFC2',
                                  strokeWidth: 10,
                                  strokeOpacity: 0.3,
                              },
                          },
                      ],
                  },
              },
          }
        : null

    const handleRangeChange = (period, values, setFieldValue) => {
        setIsLoading(true)
        let discount = discounts?.find((discount) => {
            return discount.lock_period === parseInt(period)
        })

        if (values.amount !== '') {
            getPrice(false, values.amount, discount?.lock_id, currency).then(
                (price) => {
                    setIsLoading(false)
                    setFieldValue(
                        'amount_calc',
                        !isNaN(price)
                            ? price / 10 ** (currency === 'eth' ? 18 : 6)
                            : ''
                    )
                }
            )
        }

        const value = discounts.find(
            (discount) => discount.lock_period === parseInt(period)
        )

        setSelectedDiscount({
            x: parseInt(value.lock_period),
            y: parseFloat(value?.discount / 100),
        })
    }

    const handleInputChange = async (
        event,
        values,
        setFieldValue,
        inputAmount
    ) => {
        setInputAmount(inputAmount)
        let discount = discounts?.find(
            (discount) => discount.lock_period === parseInt(values.lock_period)
        )

        const correctedString = event.target.value.replace(/,/g, '.')

        const pattern = /^(?:(?:0|$|[1-9][0-9]*)(?:\.[0-9]*)?|\.[0-9])$/

        if (pattern.test(correctedString)) {
            setIsLoading(true)
            setFieldValue(event.target.name, correctedString)
            getPrice(
                activeField === 'amount_calc',
                correctedString,
                discount.lock_id,
                currency
            ).then((p) => {
                setIsLoading(false)
                activeField === 'amount'
                    ? setFieldValue(
                          'amount_calc',
                          !isNaN(p)
                              ? p / 10 ** (currency === 'eth' ? 18 : 6)
                              : ''
                      )
                    : setFieldValue('amount', !isNaN(p) ? p / 10 ** 18 : '')

                //const c = p?.c?.map(num => num.toString()).join('')
                //setPrice(activeField === 'amount' ? parseFloat(correctedString) : p / 10 ** 18 )
            })
        }
    }

    const closeHandler = () => {
        onClose()
        setCurrency('eth')
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={closeHandler}
            customClass={'md:w-[90%] max-w-none'}
        >
            <div className="flex flex-col text-left pt-8 pb-4 px-2 h-3/4 max-h-3/4 flex-1 relative bg-grey-100 text-grey-400 z-100">
                <Dialog.Title as="h3" className="text-title mb-3 px-8">
                    {t('bonds_title')}
                </Dialog.Title>
                <p className="overflow-auto whitespace-pre-wrap px-8 pt-2 modal-body">
                    {t('bonds_description')}
                </p>
                <Formik
                    initialValues={initialFormValues}
                    validateOnBlur={true}
                    onSubmit={(values) => {
                        // get lock_id from discounts
                        let discount = discounts.find(
                            (discount) =>
                                discount.lock_period ===
                                parseInt(values.lock_period)
                        )

                        buyBonds({
                            ...values,
                            amount: _.get(
                                values,
                                inputAmount ? 'amount_calc' : 'amount',
                                0
                            ),
                            lock_id: discount?.lock_id,
                            input_amount: inputAmount,
                        })

                        closeHandler()
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="flex flex-col p-8 h-full space-y-8">
                            <div className={`flex flex-col w-full`}>
                                <SelectItem
                                    name="token"
                                    label={t('bonds_label_payment_method')}
                                    options={paymentMethods}
                                    onChange={(e) => {
                                        setCurrency(e.target.value)
                                        setFieldValue(
                                            e.target.name,
                                            e.target.value
                                        )
                                        // reset form values
                                        setFieldValue('amount', '')
                                        setFieldValue('amount_calc', '')
                                    }}
                                />
                                <RangeItem
                                    name="lock_period"
                                    label={t('bonds_label_period')}
                                    type="range"
                                    min={discounts[0]?.lock_period}
                                    max={discounts?.length || 1}
                                    data={discounts?.map((d) => ({
                                        label: (
                                            <span className="text-[12px]">
                                                Lock{' '}
                                                <b className="font-space">
                                                    {d?.lock_period}
                                                </b>{' '}
                                                year
                                                {d?.lock_period > 1 ? 's' : ''}{' '}
                                                and receive a{' '}
                                                <b className="font-space">
                                                    {d.discount / 100}%
                                                </b>{' '}
                                                discount
                                            </span>
                                        ),
                                        value: d.lock_period,
                                    }))}
                                    onChange={(e) => {
                                        setFieldValue(
                                            e.target.name,
                                            parseInt(e.target.value)
                                        )
                                        handleRangeChange(
                                            e.target.value,
                                            values,
                                            setFieldValue
                                        )
                                    }}
                                />
                                <FormItem
                                    name="amount"
                                    label={t('bonds_label_rfrm_amount')}
                                    placeholder={0}
                                    type="text"
                                    onFocus={() => setActiveField('amount')}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            values,
                                            setFieldValue,
                                            false
                                        )
                                    }
                                />
                                <FormItem
                                    name="amount_calc"
                                    label={`${currency} ${t(
                                        'bonds_label_amount'
                                    )}`}
                                    type="text"
                                    placeholder={0}
                                    onFocus={() =>
                                        setActiveField('amount_calc')
                                    }
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            values,
                                            setFieldValue,
                                            true
                                        )
                                    }
                                />
                            </div>
                            {discounts.length > 0 ? (
                                <div className="w-full flex flex-row justify-end">
                                    <Button
                                        id="bonds_button_2"
                                        disabled={
                                            values.amount === '' || isLoading
                                        }
                                        type="submit"
                                        className="bg-primary hover:bg-grey-200"
                                    >
                                        {t('bonds_btn_buy')}
                                    </Button>
                                </div>
                            ) : (
                                <span className="text-center text-warning">
                                    Bonding is currently sold out
                                </span>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="hidden lg:flex lg:w-[375px] xl:w-[515px] bg-grey-500 flex-col items-start px-8 py-10">
                <h2 className="text-title text-grey-100 pb-16">
                    Lock in your discount
                </h2>
                <div className="w-full h-full">
                    {chartConfig && (
                        <Chart
                            ref={chartRef}
                            options={chartConfig.options}
                            series={chartConfig.series}
                            type="area"
                            width={chartWidth}
                            height="343px"
                            id="chart"
                        />
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default BondsDialog
