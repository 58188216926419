/**
 * Global components
 *
 * @author Koen Vermeulen
 */

export function RenderSVG({
    width,
    height,
    src,
    color = 'white',
    hoverColor,
    fit = true,
    className = undefined,
    id,
}) {
    const renderSVGClass = `svgIcon ${className ? className : ''}`
    return (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <div id={'svg-' + id} className={renderSVGClass}>
                <style jsx="true">{`
                    #${'svg-' + id} {
                        width: ${width}px;
                        height: ${height}px;
                        ${color ? `background: ${color};` : ''}
                        mask: url(${src});
                        mask-repeat: no-repeat;
                        mask-position: center;
                        mask-size: ${fit ? 'contain' : 'auto'};
                        transition: 0.2s;
                    }
                    #${'svg-' + id}:hover {
                        ${hoverColor ? `background: ${hoverColor};` : ''}
                    }
                `}</style>
            </div>
        </div>
    )
}
