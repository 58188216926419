import React, { useState } from 'react'
import InputSection from '../../InputSection'
import Button from '../../Button'
import { LuChevronDown, LuTrash2 } from 'react-icons/lu'
import Swal from 'sweetalert2'
import { questPlatformDeveloperEmail } from './../../../library/constants'
import { isValidJSON, Request, swalError } from '../../../library/functions'
import { UPDATE_TASKENDPOINTOPTIONS_URL } from '../../../library/urls'
import { getEthereumSignature } from '../../../library/signature'

export default function TaskEndpointOptionsList({
    initial_taskEndpointOptions,
}) {
    const [taskEndpointOptionsInput, setTaskEndpointOptionsInput] = useState(
        initial_taskEndpointOptions
    )

    if (!taskEndpointOptionsInput?.length)
        return <p className="text-white">No Taskendpionts</p>

    function handleUpdateTaskEndpoint(taskEndpointOption) {
        let new_taskEndpointOptionsInput = [...taskEndpointOptionsInput]

        //find the task endpoint to update
        let found_taskEndpointOption_index =
            new_taskEndpointOptionsInput.findIndex(
                (tEO) => tEO.value === taskEndpointOption.value
            )

        //check if found
        if (
            isNaN(found_taskEndpointOption_index) ||
            found_taskEndpointOption_index < 0
        )
            return

        //edit the specific task endpoint
        new_taskEndpointOptionsInput[found_taskEndpointOption_index] =
            taskEndpointOption

        //set
        setTaskEndpointOptionsInput(new_taskEndpointOptionsInput)
    }

    function handleSaveTaskEndpointOptions() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action is permanent and cannot be undone',
            icon: 'warning',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto: false,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                saveTaskEndpointOptions(taskEndpointOptionsInput)
            }
        })
    }

    return (
        <div className="flex flex-col gap-2">
            {taskEndpointOptionsInput.map((taskEndpointOption) => {
                return (
                    <TaskEndpointOption
                        key={taskEndpointOption.value}
                        taskEndpointOption={taskEndpointOption}
                        handleUpdateTaskEndpoint={handleUpdateTaskEndpoint}
                        handleSaveTaskEndpointOptions={
                            handleSaveTaskEndpointOptions
                        }
                    />
                )
            })}
            {!!taskEndpointOptionsInput?.length && (
                <div className="mt-4">
                    <TEOButtons
                        handleSaveTaskEndpointOptions={
                            handleSaveTaskEndpointOptions
                        }
                    />
                </div>
            )}
        </div>
    )
}

function TEOButtons({ handleSaveTaskEndpointOptions }) {
    return (
        <div className="flex gap-2 w-full justify-end">
            <Button onClick={handleSaveTaskEndpointOptions} className="!px-10">
                Save
            </Button>
        </div>
    )
}

function TaskEndpointOption({
    taskEndpointOption,
    handleUpdateTaskEndpoint,
    handleSaveTaskEndpointOptions,
}) {
    const [expanded, setExpanded] = useState()

    return (
        <div
            className="border border-neutral-500 bg-neutral-800/20 rounded-md px-4 py-3 cursor-pointer select-none"
            onClick={() => {
                setExpanded(!expanded)
            }}
        >
            <TaskEndpointOptionTitle
                taskEndpointOption={taskEndpointOption}
                expanded={expanded}
                setExpanded={setExpanded}
            />
            {expanded && (
                <TaskEndpointOptionExpanded
                    taskEndpointOption={taskEndpointOption}
                    handleUpdateTaskEndpoint={handleUpdateTaskEndpoint}
                    handleSaveTaskEndpointOptions={
                        handleSaveTaskEndpointOptions
                    }
                />
            )}
        </div>
    )
}

function TaskEndpointOptionTitle({
    taskEndpointOption,
    expanded,
    setExpanded,
}) {
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
                <p className="text-white text-base">
                    {taskEndpointOption.display}
                </p>
                <p className="text-neutral-500 text-sm">
                    {taskEndpointOption.value}
                </p>
            </div>
            <div className="flex items-center gap-2">
                <Button
                    variant="ghost"
                    className="!px-2 !py-2 !text-neutral-500"
                    onClick={() => {
                        Swal.fire({
                            title: 'How to delete',
                            text: `Deletion of a task endpoint is not possible, even not through this superadmin editor as it will break every aspect of the QuestPlatform. Ask the developer for more information. Current developer: ${questPlatformDeveloperEmail}`,
                            icon: 'info',
                            confirmButtonText: 'Continue',
                            allowOutsideClick: true,
                            heightAuto: false,
                        })
                    }}
                >
                    <LuTrash2 />
                </Button>
                <Button
                    variant="ghost"
                    className="!px-2 !py-2 !text-neutral-200 !text-base"
                    onClick={() => {
                        setExpanded(!expanded)
                    }}
                >
                    <LuChevronDown
                        className={`duration-150 ${
                            expanded ? 'rotate-180' : ''
                        }`}
                    />
                </Button>
            </div>
        </div>
    )
}

function TaskEndpointOptionExpanded({
    taskEndpointOption,
    handleUpdateTaskEndpoint,
    handleSaveTaskEndpointOptions,
}) {
    const [additionalPropsDisabled, setAdditionalPropsDisabled] = useState(true)
    const [additionalPropsHovering, setAdditionalPropsHovering] =
        useState(false)

    return (
        <div className="flex flex-col gap-2 mt-3">
            <InputSection
                title="Display"
                explainer={`Post title, e.g.: ${taskEndpointOption.display} ReformDAO`}
                value={taskEndpointOption.display}
                setValue={(value) => {
                    let new_taskEndpointOption = {
                        ...taskEndpointOption,
                        display: value,
                    }
                    handleUpdateTaskEndpoint(new_taskEndpointOption)
                }}
            />

            <InputSection
                title="XP"
                explainer={`The amount of XP each task completion is worth`}
                value={taskEndpointOption.XP}
                setValue={(value) => {
                    if (isNaN(parseInt(value))) return
                    let new_taskEndpointOption = {
                        ...taskEndpointOption,
                        XP: parseInt(value),
                    }
                    handleUpdateTaskEndpoint(new_taskEndpointOption)
                }}
            />
            <div className="border-t border-neutral-800 mt-4 mb-3"></div>
            <InputSection
                title="Label"
                explainer={`only showin in the editor dashboard`}
                value={taskEndpointOption.label}
                setValue={(value) => {
                    let new_taskEndpointOption = {
                        ...taskEndpointOption,
                        label: value,
                    }
                    handleUpdateTaskEndpoint(new_taskEndpointOption)
                }}
            />
            <InputSection
                title="Action Link"
                explainer={`The "Go" button link. Use {{}} to insert task props in the action link`}
                value={taskEndpointOption.actionLink}
                setValue={(value) => {
                    let new_taskEndpointOption = {
                        ...taskEndpointOption,
                        actionLink: value,
                    }
                    handleUpdateTaskEndpoint(new_taskEndpointOption)
                }}
            />
            <div
                onMouseEnter={() => {
                    setAdditionalPropsHovering(true)
                }}
                onMouseLeave={() => {
                    setAdditionalPropsHovering(false)
                    setAdditionalPropsDisabled(true)
                }}
            >
                {additionalPropsHovering && additionalPropsDisabled ? (
                    <div
                        className="cursor-default"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <p className="text-neutral-400 mb-1.5 text-sm">
                            {'Available Additional Props'}
                            <span className="text-xs ml-2 text-neutral-500">
                                {`Ask the developer if anything needs to be changed`}
                            </span>
                        </p>
                        <Button
                            variant="secondary"
                            className="!py-[11px] w-full !rounded-md !bg-neutral-600 !text-white"
                            onClick={() => {
                                Swal.fire({
                                    title: 'Edit Additional Props',
                                    text: 'These settings are made for developers and need to be extremely specific to work.',
                                    icon: 'info',
                                    confirmButtonText: 'Continue',
                                    allowOutsideClick: true,
                                    heightAuto: false,
                                }).then(() => {
                                    setAdditionalPropsDisabled(false)
                                })
                            }}
                        >
                            Edit {'(dev settings)'}
                        </Button>
                    </div>
                ) : (
                    <InputSection
                        title="Available Additional Props"
                        explainer={`Ask the developer if anything needs to be changed`}
                        value={JSON.stringify(
                            taskEndpointOption.availableAdditionalProps
                        )}
                        disabled={additionalPropsDisabled}
                        setValue={(value) => {
                            if (!isValidJSON(value)) return
                            let json_value = JSON.parse(value)
                            let new_taskEndpointOption = {
                                ...taskEndpointOption,
                                availableAdditionalProps: json_value,
                            }
                            handleUpdateTaskEndpoint(new_taskEndpointOption)
                        }}
                    />
                )}
            </div>
            <div className="w-full flex justify-end mt-3 mb-1">
                <Button
                    variant="secondary"
                    className="!px-8"
                    onClick={handleSaveTaskEndpointOptions}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

async function saveTaskEndpointOptions(new_taskEndpointOptions) {
    try {
        if (!new_taskEndpointOptions)
            throw new Error('No new_taskEndpointOptions')

        let taskEndpointOptions_JSONstring = JSON.stringify(
            new_taskEndpointOptions
        )
        if (!isValidJSON(taskEndpointOptions_JSONstring))
            throw new Error('Not a valid JSON input')

        let updateObjString = taskEndpointOptions_JSONstring

        const { signature, wallet } = await getEthereumSignature()
        if (!signature)
            return swalError('No signature available. Sign in with metamask')

        const config = {
            url: UPDATE_TASKENDPOINTOPTIONS_URL,
            method: 'post',
            data: {
                updateParams: updateObjString,
                access_wallet: wallet,
                access_signature: signature,
            },
        }

        Request(config)
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated',
                        icon: 'success',
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        heightAuto: false,
                    }).then(() => {
                        //hard reload
                        window.location.href = `${window.location.origin}${window.location.pathname}?superadmin-route=task-adjuster`
                    })
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong. ' + response.message,
                        icon: 'error',
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        heightAuto: false,
                    }).then(() => {
                        //hard reload
                    })
                }
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong. Error: ' + err.message,
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                })
            })
    } catch (err) {
        Swal.fire({
            title: 'Error',
            text: 'Something went wrong. ' + err,
            icon: 'error',
            confirmButtonText: 'Continue',
            allowOutsideClick: true,
            heightAuto: false,
        })
        console.log(err)
    }
}
