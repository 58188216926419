import React from 'react'
import MaxWidthContainer from '../../MaxWidthContainer'
import heroBackground from './../../../assets/svg/backgrounds/questplatform/questplatform-background.png'
import { questPlatform_inMaintenance } from '../../../library/constants'
import QuestplatformHero from './QuestplatformHero'

export default function QuestplatformLayout({ children, title }) {
    return (
        <MaxWidthContainer
            size="xl"
            backgroundColor={'#202020'}
            bgImage={heroBackground}
            bgOpacity={100}
        >
            <div
                className={
                    'grid grid-rows-[auto_1fr] h-full lg:px-6 px-3 lg:pb-[80px] pb-[40px] lg:pt-6 pt-[60px] w-full grid-cols-1'
                }
            >
                <QuestplatformHero title={title} />
                {questPlatform_inMaintenance ? <Maintenance /> : children}
            </div>
        </MaxWidthContainer>
    )
}

function Maintenance() {
    return (
        <div className="flex py-20 justify-center text-white">
            In maintenance
        </div>
    )
}
