import React, { useState } from 'react'
import { useSelectedTaskState } from './../../../library/store/selectedTaskGategoryStore'

import CountUp from 'react-countup'
import chevron from './../../../assets/svg/reform-chevron.svg'
import {
    calculateDurationObject,
    convertParamsVariableString,
    hasDecimals,
} from './../../../library/functions'
import { RenderSVG } from './../../../library/globalComponents'
import { LuShieldCheck } from 'react-icons/lu'

export default function SuperadminTaskCard({ superadminTask }) {
    const { selectedTask, setSelectedTask } = useSelectedTaskState()
    const taskExpanded = selectedTask?.id === superadminTask?.id

    let additionalPropsArray = superadminTask.additionalProps

    const totalXP = superadminTask?.totalXPCollected || 0

    let duration_seconds = superadminTask?.durationSections
    let duration_object = calculateDurationObject(duration_seconds)

    return (
        <div
            className="bg-gradient-to-r from-[#FFFFFF99] to-[#00FFC299] from-60% p-[1px] rounded-xl cursor-pointer select-none"
            onClick={() => {
                if (!taskExpanded) {
                    setSelectedTask(superadminTask)
                } else {
                    setSelectedTask(null)
                }
            }}
        >
            <div className="bg-gradient-to-r from-[#1b1b1b] to-[#3A3A3A] from-30% py-3 px-4 rounded-xl">
                <div className="grid grid-cols-[auto_1fr_auto] gap-5">
                    {superadminTask.taskCategory && superadminTask?.id ? (
                        <div className="flex items-center h-[25px] w-fit">
                            <RenderSVG
                                src={superadminTask.taskCategory.icon}
                                id={superadminTask.id}
                                color={'white'}
                                hoverColor={'#f0f0f0'}
                                width={25}
                                height={25}
                            />
                        </div>
                    ) : (
                        <div className=""></div>
                    )}
                    <div className="text-neutral-100">
                        <div className="flex items-start justify-between w-full">
                            <h3>
                                {convertParamsVariableString(
                                    superadminTask.title,
                                    additionalPropsArray
                                )}
                            </h3>
                            <div className="flex items-center gap-4">
                                <p className="text-neutral-400 min-w-fit flex items-center gap-1">
                                    {superadminTask.completions || 0}
                                    <LuShieldCheck className="text-sm text-neutral-500" />{' '}
                                </p>
                                <p className="text-neutral-400 min-w-fit">
                                    {totalXP}
                                    <span className="text-xs ml-1 text-neutral-500">
                                        XP
                                    </span>
                                </p>
                                {duration_object?.amount && (
                                    <p className="text-neutral-400 min-w-fit">
                                        {Math.round(duration_object.amount)}
                                        <span className="text-xs ml-1 text-neutral-500">
                                            {duration_object.unit}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                        {taskExpanded && (
                            <TaskExpanded
                                selectedTask={superadminTask}
                                duration_object={duration_object}
                            />
                        )}
                    </div>
                    <div className="flex justify-end items-center h-[25px]">
                        <img
                            src={chevron}
                            alt="chevron"
                            className={`transition-100 transform ${
                                taskExpanded ? 'rotate-180	' : 'rotate-0	'
                            }`}
                            width={20}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

function TaskExpanded({ selectedTask, duration_object }) {
    return (
        <div className="">
            <div className="pt-5 pb-4 flex items-center flex-wrap gap-x-5 gap-y-2">
                <TaskExpandedStat
                    title={'completions'}
                    stat={selectedTask?.completions}
                />
                <TaskExpandedStat
                    title={'collected'}
                    suffix={'XP'}
                    stat={selectedTask?.totalXPCollected}
                />
                {duration_object?.amount && (
                    <TaskExpandedStat
                        title={'goal reached'}
                        suffix={duration_object.unit}
                        stat={duration_object.amount}
                    />
                )}
            </div>
            <div className="border-b border-neutral-700 h-[1px] w-full mb-4"></div>
            <div className="flex flex-col gap-1 mb-3">
                <p className="text-white">All props</p>
                <TaskProp title={'Id'} prop={selectedTask?.id} />
                <TaskProp title={'Created At'} prop={selectedTask?.createdAt} />
                <TaskProp title={'Updated At'} prop={selectedTask?.updatedAt} />
                <TaskProp title={'Title'} prop={selectedTask?.title} />
                <TaskProp
                    title={'Description'}
                    prop={selectedTask?.description}
                />
                <TaskProp
                    title={'Profile Picture'}
                    prop={selectedTask?.profilePicture}
                />
                <TaskProp
                    title={'XPs'}
                    prop={JSON.stringify(selectedTask?.XPs)}
                />
                <TaskProp
                    title={'Task URLs'}
                    prop={JSON.stringify(selectedTask?.taskURLs)}
                />
                <TaskProp
                    title={'Additional Props'}
                    prop={JSON.stringify(selectedTask?.additionalProps)}
                />
                <TaskProp
                    title={'Termination Mode'}
                    prop={selectedTask?.terminationMode}
                />
                <TaskProp
                    title={'Max Amount Of Completions'}
                    prop={selectedTask?.maxAmountOfCompletions}
                />
                <TaskProp title={'Start Date'} prop={selectedTask?.startDate} />
                <TaskProp title={'End Date'} prop={selectedTask?.endDate} />
                <TaskProp title={'order'} prop={selectedTask?.order} />
                <TaskProp
                    title={'Task Category Id'}
                    prop={selectedTask?.taskCategoryId}
                />
                <TaskProp
                    title={'Community Names'}
                    prop={selectedTask?.communityNames?.toString() || '-'}
                />
                <TaskProp
                    title={'Last completion'}
                    prop={
                        !!selectedTask.TaskCompletion?.length
                            ? selectedTask.TaskCompletion[
                                  selectedTask.TaskCompletion.length - 1
                              ]?.createdAt
                            : ' - '
                    }
                />
            </div>
        </div>
    )
}

function TaskExpandedStat({ title, stat, suffix }) {
    return (
        <div className="flex items-center gap-2">
            <div className="flex items-end">
                <CountUp
                    start={0}
                    end={stat}
                    duration={0.6}
                    useEasing={true}
                    useGrouping={true}
                    decimals={hasDecimals(stat) ? 2 : 0}
                    separator=","
                />
                {suffix && (
                    <p className="text-xs font-semibold ml-1 mb-0.5 text-neutral-500">
                        {suffix}
                    </p>
                )}
            </div>

            <p className="text-neutral-400 text-sm">{title}</p>
        </div>
    )
}

function TaskProp({ title, prop }) {
    if (!prop) return <></>

    return (
        <div className="grid grid-cols-[200px_auto] w-full items-center">
            <p className="text-neutral-200 text-sm text-wrap">{title}</p>
            <p className="text-neutral-400 text-sm text-wrap break-all">
                {prop.toString()}
            </p>
        </div>
    )
}

function getDurationSeconds(superadminTask) {
    let startDate = new Date(superadminTask.startDate)
    let endDate = superadminTask.endDate
        ? new Date(superadminTask.endDate)
        : null
    let now = new Date()
    let completionLength = superadminTask.TaskCompletion?.length || 0
    let maxCompletionsReached = superadminTask.maxAmountOfCompletions
        ? completionLength >= superadminTask.maxAmountOfCompletions
        : null
    let endDateReached = endDate && endDate >= now

    let lastCompletion = null
    let lastCompletionDate = null
    if (maxCompletionsReached || endDateReached) {
        lastCompletion = superadminTask.TaskCompletion[completionLength - 1]
        lastCompletionDate = new Date(lastCompletion.createdAt)
    }

    let duration = lastCompletionDate ? lastCompletionDate - startDate : null
    let duration_seconds = duration ? Math.round(duration / 1000) : null
    return duration_seconds
}
