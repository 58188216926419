import React from 'react'
import ProfileSettingsGrid from '../../components/questPlatform/user/ProfileSettingsGrid'
import QuestplatformLayout from '../../components/questPlatform/layout/QuestplatformLayout'

export default function QuestPlatformProfileSettings() {
    return (
        <QuestplatformLayout>
            <ProfileSettingsGrid />
        </QuestplatformLayout>
    )
}
