import axios from 'axios'
import { RFRMAPI } from '../config/endpoints'

export const apiCall = async (
    path,
    { payload, method = 'GET' },
    tokens = {}
) => {
    const url = `${RFRMAPI(process.env.REACT_APP_HOST_ENV)}/${path.replace(/^\//, '')}`

    const headers = {}
    if (Object.entries(tokens).length > 0) {
        let _tokens = tokens
        if (_tokens?.expiry - Date.now() / 1000 < 60) {
            // tokens expired
            try {
                const { access_token, refresh_token, expiry } = await apiCall(
                    '/auth/refresh',
                    {
                        method: 'POST',
                        payload: { refresh_token: _tokens?.refresh },
                    }
                )
                _tokens = {
                    expiry,
                    access: access_token,
                    refresh: refresh_token,
                }
                tokens?.set(_tokens)
            } catch (e) {
                if (e?.response?.status === 403) {
                    // refresh token expired
                    await tokens?.clear()
                }
            }
        }
        headers['Authorization'] = `Bearer ${_tokens?.access}`
    }

    const { status, data } = await axios({
        method,
        url,
        headers,
        data: payload,
        // transformResponse: function(response) {
        //     return JSONbigint().parse(response);
        // }
    })
    if (status > 299) {
        throw new Error({ response: { status, data: { message: 'Failed' } } })
    }
    return data
}
