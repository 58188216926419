import { useEffect, useState } from 'react'
import { LuPlusCircle } from 'react-icons/lu'
import { v4 as uuidv4 } from 'uuid'
import { makeIndexArray, paginateArray } from '../../../library/functions'
import Button from './../../../components/Button'
import { useSelectedTaskCategoryState } from './../../../library/store/selectedTaskGategoryStore'
import Card from './../../Card'
import Loader from './../../Loader'
import {
    PaginationNext,
    PaginationPrevious,
} from './../../shadcnui/ui/pagination'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from './../../shadcnui/ui/select'
import SuperadminTaskCard from './SuperadminTaskCard'

export default function SuperadminTasks({
    tasks,
    user,
    handleAddTask,
    setSelectedTask,
    paginationIndex,
    setPaginationIndex,
    paginationSize,
    setPaginationSize,
    maxPaginationPages,
}) {
    const { selectedTaskCategory, setSelectedTaskCategory } =
        useSelectedTaskCategoryState()

    if (!user?.wallet)
        return (
            <Card
                className={
                    'h-full py-20 box-border overflow-y-scroll w-full flex justify-center '
                }
            >
                <p className="text-white">Connect wallet</p>
            </Card>
        )

    if (tasks === null)
        return (
            <Card
                className={'h-full  py-20 box-border overflow-y-scroll w-full'}
            >
                <Loader />
            </Card>
        )
    if (!tasks?.length)
        return (
            <Card className={'h-full box-border overflow-y-scroll w-full'}>
                {handleAddTask ? (
                    <AddTaskButton handleAddTask={handleAddTask} />
                ) : (
                    <p className="text-white py-4 text-center w-full">
                        No Tasks found
                    </p>
                )}
            </Card>
        )

    let catTasks = tasks

    if (selectedTaskCategory) {
        //a category is selected
        catTasks = tasks.filter(
            (task) => task.taskCategoryId === selectedTaskCategory.id
        )
    }

    //order
    catTasks = catTasks.sort((a, b) => {
        const orderA = a.order !== null ? a.order : 0
        const orderB = b.order !== null ? b.order : 0
        return orderB - orderA // Highest number first
    })

    return (
        <Card className="h-full box-border overflow-y-scroll w-full p-5 pt-4 grid grid-rows-[1fr_auto]">
            <div className="flex flex-col gap-3 w-full">
                {handleAddTask && (
                    <AddTaskButton handleAddTask={handleAddTask} />
                )}{' '}
                {tasks.map((task) => {
                    return (
                        <SuperadminTaskCard
                            superadminTask={task}
                            user={user}
                            key={uuidv4()}
                            setSelectedTask={setSelectedTask}
                        />
                    )
                })}
            </div>
            <PaginationSelector
                paginationIndex={paginationIndex}
                setPaginationIndex={setPaginationIndex}
                paginationAmount={paginationSize}
                setPaginationAmount={setPaginationSize}
                totalLength={(maxPaginationPages || 1) * paginationSize}
            />
        </Card>
    )
}

function AddTaskButton({ handleAddTask }) {
    return (
        <Button
            variant="ghost"
            className="w-full !py-3.5 mb-1"
            onClick={handleAddTask}
        >
            <LuPlusCircle />
            Add task
        </Button>
    )
}

function PaginationSelector({
    paginationIndex,
    setPaginationIndex,
    paginationAmount,
    setPaginationAmount,
    totalLength,
}) {
    const max_pagination_buttons = 5
    const paginationAmounts = [5, 10, 15, 20, 50, 100]
    let paginationTabs_number = Math.ceil(totalLength / paginationAmount)
    let paginationTabs = makeIndexArray(paginationTabs_number)

    let first_paginationTabs = paginationTabs.slice(
        Math.max(0, paginationIndex - Math.floor(max_pagination_buttons / 2)),
        Math.min(
            paginationTabs_number,
            paginationIndex + Math.floor(max_pagination_buttons / 2) + 1
        )
    )

    function handleSelectPaginationIndex(index) {
        setPaginationIndex(index)
    }
    function handlePrevious() {
        let previous_index = paginationIndex - 1
        if (previous_index < 0) return
        setPaginationIndex(previous_index)
    }
    function handleNext() {
        let next_index = paginationIndex + 1
        if (next_index >= paginationTabs_number) return
        setPaginationIndex(next_index)
    }

    return (
        <div className="w-full flex items-center justify-between gap-6 select-none mt-3">
            <div className="flex items-center justify-between w-full">
                <PaginationPrevious
                    displayText={false}
                    onClick={handlePrevious}
                />

                <div className="flex items-center">
                    {paginationIndex - Math.floor(max_pagination_buttons / 2) >
                        0 && (
                        <Button
                            variant="ghost"
                            className={`!py-2 tracking-[-2px] pl-[8px] pr-[10px] !text-neutral-500`}
                            onClick={() => setPaginationIndex(0)}
                        >
                            {'...'}
                        </Button>
                    )}
                    {first_paginationTabs.map((first_paginationIndex) => {
                        const selected =
                            paginationIndex === first_paginationIndex
                        return (
                            <Button
                                variant="ghost"
                                className={`!px-3 !py-2 ${
                                    selected ? '!text-primary' : ''
                                }`}
                                onClick={() =>
                                    handleSelectPaginationIndex(
                                        first_paginationIndex
                                    )
                                }
                            >
                                {first_paginationIndex + 1}
                            </Button>
                        )
                    })}
                    {paginationIndex +
                        Math.floor(max_pagination_buttons / 2) +
                        1 <
                        paginationTabs_number && (
                        <Button
                            variant="ghost"
                            className={` !py-2 tracking-[-2px] pl-[8px] pr-[10px] !text-neutral-500`}
                            onClick={() =>
                                setPaginationIndex(paginationTabs_number - 1)
                            }
                        >
                            {'...'}
                        </Button>
                    )}
                </div>
                <PaginationNext displayText={false} onClick={handleNext} />
            </div>
            <Select
                onValueChange={(amount) => {
                    setPaginationAmount(amount)
                    setPaginationIndex(0)
                }}
                value={paginationAmount}
            >
                <SelectTrigger className="w-[130px]">
                    <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                    {paginationAmounts.map((_paginationAmount) => {
                        return (
                            <SelectItem value={_paginationAmount}>
                                {_paginationAmount}
                            </SelectItem>
                        )
                    })}
                </SelectContent>
            </Select>
        </div>
    )
}
