import React, { useState } from 'react'
import Card from '../../Card'
import Loader from '../../Loader'
import CountUp from 'react-countup'

export default function StatCard({ stat, subtitle, title, suffix }) {
    const [hovering, setHovering] = useState(false)

    return (
        <Card
            className={'p-6 lg:min-h-[180px]'}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <div className="flex flex-col items-center justify-between h-full text-center">
                {stat !== undefined && stat !== null ? (
                    <h3 className="text-white mb-4 h-full flex items-center text-3xl">
                        <div className="flex items-end">
                            <CountUp
                                start={0}
                                end={stat}
                                duration={2.75}
                                useEasing={true}
                                useGrouping={true}
                                separator=","
                            />
                            {suffix && (
                                <p className="text-lg font-semibold ml-1.5 text-neutral-500">
                                    {suffix}
                                </p>
                            )}
                        </div>
                    </h3>
                ) : (
                    <div className="py-5 mb-3 h-full">
                        <Loader width={22} />
                    </div>
                )}
                <p className="text-neutral-400 text-sm">
                    {title?.toUpperCase()}
                </p>
                {subtitle && hovering && (
                    <p className="text-neutral-600 text-xs mt-2">
                        {subtitle?.toUpperCase()}
                    </p>
                )}
            </div>
        </Card>
    )
}
