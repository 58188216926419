import React, { useEffect, useState } from 'react'
import { useQuestplatformUser } from '../../../utils/hooks/useQuestplatformUser'
import Input from '../../Input'
import { hasDecimals, Request } from '../../../library/functions'
import CountUp from 'react-countup'
import { Skeleton } from '../../shadcnui/ui/skeleton'
import {
    GET_REFERRAL_LINK_URL,
    GET_USER_REFERRALSTATS_URL,
} from '../../../library/urls'
import CopyToClipboard from '../../CopyToClipboard'
import BackArrowContainer from '../layout/BackArrowContainer'
import { useNavigate } from 'react-router-dom'

export default function QuestPlatformReferralBody() {
    const { questplatformUser } = useQuestplatformUser()
    const [referralLink, setReferralLink] = useState(null)
    const [referralStats, setReferralStats] = useState(null)

    useEffect(() => {
        if (!questplatformUser) return
        //on initial load get referralLink and referralStats
        getReferralLink(questplatformUser.wallet, setReferralLink)
        getReferralStats(questplatformUser.wallet, setReferralStats)
    }, [questplatformUser])

    return (
        <div className="">
            <ReferralLink referralLink={referralLink} />
            <ReferralStats referralStats={referralStats} />
        </div>
    )
}

function ReferralLink({ referralLink }) {
    const navigate = useNavigate()

    return (
        <div className="lg:mb-[80px] mb-[40px]">
            <BackArrowContainer action={() => navigate('/novus')}>
                REFERRAL LINK
            </BackArrowContainer>
            {referralLink ? (
                <Input
                    title="URL"
                    className={'!py-3.5'}
                    suffix={
                        <CopyToClipboard
                            text={referralLink}
                            className="-mt-4 text-white/60"
                        />
                    }
                    disableInput
                    defaultValue={referralLink}
                ></Input>
            ) : (
                <Skeleton className="w-full h-[60px]" />
            )}
        </div>
    )
}

function ReferralStats({ referralStats }) {
    return (
        <div className="w-full">
            <ReferralStatNumbers referralStats={referralStats} />
        </div>
    )
}

function ReferralStatNumbers({ referralStats }) {
    let totalPoints_earned = referralStats?.total_pointsEarned
    let total_referredUsers = referralStats?.total_referredUsers

    return (
        <div className="lg:grid grid-cols-2 flex flex-col gap-6 w-full">
            <ReferralStatNumber
                title={'total points earned'}
                stat={totalPoints_earned}
                suffix={' XP'}
            />
            <ReferralStatNumber
                title={'Referred Users'}
                stat={total_referredUsers}
                color={'white'}
            />
        </div>
    )
}

function ReferralStatNumber({ title, stat, suffix, color }) {
    return (
        <div className="w-full">
            <h3 className="lg:text-xl text-md font-mono tracking-wider text-white mb-4">
                {title?.toUpperCase()}
            </h3>
            {stat !== null && stat !== undefined ? (
                <h3
                    className="flex items-start text-[55px] text-primary font-mono"
                    style={{ color: color }}
                >
                    <CountUp
                        start={0}
                        end={stat}
                        duration={0.6}
                        useEasing={true}
                        useGrouping={true}
                        decimals={hasDecimals(stat) ? 2 : 0}
                        separator=","
                    />
                    {suffix && <span>{suffix}</span>}
                </h3>
            ) : (
                <Skeleton className={'w-full max-w-[300px] h-[90px]'} />
            )}
        </div>
    )
}

async function getReferralLink(wallet, setReferralLink) {
    if (!wallet || !setReferralLink) return

    const config = {
        url: GET_REFERRAL_LINK_URL,
        method: 'get',
        //with editing only provide data that needs changing
        data: {
            wallet: wallet,
        },
    }

    Request(config)
        .then((response) => {
            const link = response?.link
            if (!link) return

            setReferralLink(link)
        })
        .catch((err) => {
            console.log(err)
        })
}

async function getReferralStats(wallet, setReferralStats) {
    if (!wallet || !setReferralStats) return

    const config = {
        url: GET_USER_REFERRALSTATS_URL,
        method: 'get',
        //with editing only provide data that needs changing
        data: {
            wallet: wallet,
        },
    }

    Request(config)
        .then((response) => {
            const userStats = response?.userStats
            if (!userStats) return

            setReferralStats(userStats)
        })
        .catch((err) => {
            console.log(err)
        })
}
