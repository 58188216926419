import React from 'react'
import QuestplatformLayout from '../../components/questPlatform/layout/QuestplatformLayout'
import QuestPlatformVaultBody from '../../components/questPlatform/user/QuestPlatformVaultBody'

export default function QuestPlatformVault() {
    return (
        <QuestplatformLayout title="VAULT">
            <QuestPlatformVaultBody />
        </QuestplatformLayout>
    )
}
