import { lazy } from 'react'
import QuestPlatformSuperadmin from '../pages/QuestPlatform/QuestPlatformSuperadmin'
import QuestPlatformProfileSettings from '../pages/QuestPlatform/QuestPlatformProfileSettings'
import QuestPlatformReferral from '../pages/QuestPlatform/QuestPlatformReferral'
import QuestPlatformShop from '../pages/QuestPlatform/QuestPlatformShop'
import QuestPlatformVault from '../pages/QuestPlatform/QuestPlatformVault'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const Trading = lazy(() => import('../pages/Trading'))
const Bonds = lazy(() => import('../pages/Bonds'))
const Staking = lazy(() => import('../pages/Staking'))
const Voting = lazy(() => import('../pages/Voting'))
const Leaderboard = lazy(() => import('../pages/Leaderboard'))
const AirdropChecker = lazy(() => import('../pages/AirdropChecker'))
const QuestPlatform = lazy(() => import('../pages/QuestPlatform/QuestPlatform'))
const QuestPlatformEditor = lazy(() =>
    import('../pages/QuestPlatform/QuestPlatformEditor')
)

const routes = [
    {
        path: '/',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Overview',
        element: <Dashboard />,
    },
    // {
    //     path: '/soon1',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon1',
    //     element: <DashboardSoon/>
    // },
    // {
    //     path: '/soon2',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon2',
    //     element: <Soon/>
    // },
    {
        path: '/trading',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Trading',
        element: <Trading />,
    },
    /*    {
        path: '/DAOs',
        exact: true,
        menuItem: true,
        title: 'DOAs',
        element: <Daos/>
    },*/
    {
        path: '/bonds',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Bonds',
        element: <Bonds />,
    },
    {
        path: '/staking',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Staking',
        element: <Staking />,
    },
    {
        path: '/voting',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Voting',
        element: <Voting />,
    },

    // {
    //     path: '/leaderboard',
    //     exact: true,
    //     menuItem: true,
    //     useLayout: true,
    //     title: 'Leaderboard',
    //     element: <Leaderboard />,
    // },
    {
        path: '/airdrop-checker',
        exact: true,
        menuItem: false,
        useLayout: true,
        title: 'Airdrop checker',
        element: <AirdropChecker />,
    },
    {
        path: '/novus',
        exact: true,
        menuItem: true,
        useLayout: false,
        title: 'Novus',
        element: <QuestPlatform />,
    },
    {
        path: '/novus-settings',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Profile Settings',
        element: <QuestPlatformProfileSettings />,
    },
    {
        path: '/novus-referral',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Referral',
        element: <QuestPlatformReferral />,
    },
    {
        path: '/novus-shop',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Shop',
        element: <QuestPlatformShop />,
    },
    {
        path: '/novus-vault',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Vault',
        element: <QuestPlatformVault />,
    },
    {
        path: '/novus-editor',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Editor',
        element: <QuestPlatformEditor />,
    },
    {
        path: '/novus-superadmin',
        exact: true,
        menuItem: false,
        useLayout: false,
        title: 'Novus Superadmin',
        element: <QuestPlatformSuperadmin />,
    },
]

export default routes
