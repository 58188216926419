import React from 'react'
import QuestplatformLayout from '../../components/questPlatform/layout/QuestplatformLayout'
import QuestPlatformShopBody from '../../components/questPlatform/user/QuestPlatformShopBody'

export default function QuestPlatformShop() {
    return (
        <QuestplatformLayout title="SHOP">
            <QuestPlatformShopBody />
        </QuestplatformLayout>
    )
}
