import React, { useEffect } from 'react'
import { LuPencil } from 'react-icons/lu'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { includesAny, isMobileSize } from '../library/functions'
import { RenderSVG } from '../library/globalComponents'
import { useQuestplatformUser } from '../utils/hooks/useQuestplatformUser'
import referIcon from './../../src/assets/svg/icons/refer-icon.svg'
import settingsIcon from './../../src/assets/svg/icons/settings-icon.svg'
import shopIcon from './../../src/assets/svg/icons/shop-icon.svg'
import Button from './Button'
import QuestPlatformStats from './questPlatform/user/QuestPlatformStats'
import QuestPlatformUser from './questPlatform/user/QuestPlatformUser'
import TooltipContainer from './TooltipContainer'
import FlyInOutComponent from './FlyInOutComponent'
import { BsSafe2 } from 'react-icons/bs'

export default function ConnectWalletButton({ isOpen, toggle }) {
    const { questplatformUser } = useQuestplatformUser()

    useEffect(() => {
        //on load
        if (!isMobileSize() && !isOpen) {
            toggle()
        }
    }, [])

    let button_message = 'Connect wallet'
    if (!!questplatformUser) button_message = 'Wallet'
    if (isOpen) button_message = 'Close'

    return (
        <div className="relative w-fit mr-2">
            <Button
                variant={isOpen ? 'border' : 'primary'}
                className="!px-3 py-2 mr-[14px] lg:w-[170px] w-full"
                onClick={toggle}
            >
                {button_message}
            </Button>

            <div className="relative">
                <ConnectWalletPopup
                    isOpen={isOpen}
                    toggleIsOpen={toggle}
                    questplatformUser={questplatformUser}
                />
            </div>
        </div>
    )
}

function ConnectWalletPopup({ isOpen, toggleIsOpen, questplatformUser }) {
    return (
        <FlyInOutComponent
            isVisible={isOpen}
            setIsVisible={toggleIsOpen}
            className={
                'absolute top-[22px] lg:right-[10px] -right-[80px] max-w-[calc(100vw-10px)] bg-gradient-to-b from-[#3232325f] to-[#4a4a4a64] border border-[#63636353] rounded-xl pl-8 pr-10 py-6 pb-4 backdrop-blur-[3px] z-20 max-h-[400px] overflow-y-auto'
            }
        >
            <div className="flex flex-col lg:min-w-[600px]">
                <div
                    className={`lg:grid  flex flex-col justify-center w-full pt-6 pb-4 ${
                        questplatformUser
                            ? 'grid-cols-[auto_1fr]'
                            : '!flex items-center'
                    }`}
                >
                    <Profile questplatformUser={questplatformUser} />
                    <ProfileStats questplatformUser={questplatformUser} />
                </div>
                <Routes
                    disabled={!questplatformUser}
                    questplatformUser={questplatformUser}
                    toggleIsOpen={toggleIsOpen}
                />
            </div>
        </FlyInOutComponent>
    )
}

function Profile({ questplatformUser }) {
    return (
        <div className="max-w-[300px]">
            <QuestPlatformUser questPlatformUser={questplatformUser} />{' '}
        </div>
    )
}

function ProfileStats({ questplatformUser }) {
    if (!questplatformUser) return

    return <QuestPlatformStats user={questplatformUser} />
}

function Routes({ disabled, questplatformUser, toggleIsOpen }) {
    const navigate = useNavigate()

    function handleToggleQPPath(path) {
        //navigate to the main quest platform when current path is the active path
        if (window.location.pathname === path) {
            navigate('/novus')
        } else {
            navigate(path)
        }

        if (isMobileSize()) {
            toggleIsOpen()
        }
    }

    return (
        <div className="flex flex-wrap items-center gap-x-3 gap-y-2">
            <Button
                variant="ghost"
                className={`!px-3 !py-2 !font-sans ${
                    window.location.pathname === '/novus-settings'
                        ? '!text-primary'
                        : ''
                }`}
                disabled={disabled}
                onClick={() => handleToggleQPPath('/novus-settings')}
            >
                <RenderSVG
                    id="settings-icon"
                    width={18}
                    height={18}
                    src={settingsIcon}
                    color={'currentColor'}
                    hoverColor={'white'}
                    className="w-[18px] h-[18px]"
                />
                PROFILE SETTINGS
            </Button>
            <Button
                variant="ghost"
                className={`!px-3 !py-2 !font-sans  ${
                    window.location.pathname === '/novus-referral'
                        ? '!text-primary'
                        : ''
                }`}
                disabled={disabled}
                onClick={() => handleToggleQPPath('/novus-referral')}
            >
                <RenderSVG
                    id="referral-icon"
                    width={18}
                    height={18}
                    src={referIcon}
                    color={'currentColor'}
                    hoverColor={'white'}
                    className="w-[18px] h-[18px]"
                />
                REFERRAL PROGRAM
            </Button>
            <Button
                variant="ghost"
                className={`!px-3 !py-2 !font-sans  ${
                    window.location.pathname === '/novus-shop'
                        ? '!text-primary'
                        : ''
                }`}
                disabled={disabled}
                onClick={() => handleToggleQPPath('/novus-shop')}
            >
                <RenderSVG
                    id="shop-icon"
                    width={18}
                    height={18}
                    src={shopIcon}
                    color={'currentColor'}
                    hoverColor={'white'}
                    className="w-[18px] h-[18px]"
                />
                SHOP
            </Button>
            <Button
                variant="ghost"
                className={`!px-3 !py-2 !font-sans  ${
                    window.location.pathname === '/novus-vault'
                        ? '!text-primary'
                        : ''
                }`}
                disabled={disabled}
                onClick={() => handleToggleQPPath('/novus-vault')}
            >
                <BsSafe2 className="w-[18px] h-[18px] -mt-[1px]" />
                VAULT
            </Button>
            {includesAny(questplatformUser?.role, ['editor', 'superadmin']) && (
                <TooltipContainer message={'Only visible to editors'}>
                    <Button
                        variant="ghost"
                        className={`!px-3 !py-2  ${
                            window.location.pathname === '/novus-editor'
                                ? '!text-primary'
                                : ''
                        }`}
                        disabled={disabled}
                        onClick={() => handleToggleQPPath('/novus-editor')}
                    >
                        <LuPencil className="w-[17px] h-[17px]" />
                        Editor dashboard
                    </Button>
                </TooltipContainer>
            )}
            {includesAny(questplatformUser?.role, ['superadmin']) && (
                <TooltipContainer message={'Only visible to superadmins'}>
                    <Button
                        variant="ghost"
                        className={`!px-3 !py-2  ${
                            window.location.pathname === '/novus-superadmin'
                                ? '!text-primary'
                                : ''
                        }`}
                        disabled={disabled}
                        onClick={() => handleToggleQPPath('/novus-superadmin')}
                    >
                        <MdOutlineAdminPanelSettings className="w-[20px] h-[20px]" />
                        Superadmin dashboard
                    </Button>
                </TooltipContainer>
            )}
        </div>
    )
}
