import { BrowserProvider } from 'ethers'

export async function getEthereumSignature() {
    if (typeof window.ethereum === 'undefined') {
        console.error(
            'Ethereum provider not found. Make sure MetaMask or another wallet is installed.'
        )
        return null
    }

    try {
        // Check if signature and wallet are already stored in cookies
        const savedSignature = getCookie('signature')
        const savedWallet = getCookie('wallet')

        if (savedSignature && savedWallet) {
            console.log('Using saved signature and wallet from cookies.')
            return { signature: savedSignature, wallet: savedWallet }
        }

        // Request wallet connection
        await window.ethereum.request({ method: 'eth_requestAccounts' })

        // Create the provider and signer
        const provider = new BrowserProvider(window.ethereum)
        const signer = await provider.getSigner()

        // Step 1: Sign a message
        const message = 'Please sign this message to authenticate your identity'
        const signature = await signer.signMessage(message)

        // Step 2: Get the wallet address
        const wallet = await signer.getAddress()

        // Step 3: Save to cookies
        setCookie('signature', signature, 7) // Expires in 7 days
        setCookie('wallet', wallet, 7)

        return { signature, wallet }
    } catch (error) {
        console.error('Error signing message or connecting to wallet:', error)
        return null
    }
}

// Helper function to set a cookie
function setCookie(name, value, days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;Secure;SameSite=Strict`
}

// Helper function to get a cookie
function getCookie(name) {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1)
        }
    }
    return null
}
