import axios from 'axios'
import Swal from 'sweetalert2'

/**
 * General functions
 *
 * @author Koen Vermeulen
 */

/**
 * Request
 * Makes an axios request
 *
 * @param config
 * @returns Promise void
 */
export const Request = (config: any) => {
    let url: string = config.url
    if (config.method === 'get') {
        let params = new URLSearchParams(config.data).toString()
        url = `${url}?${params}`
    }

    return new Promise((resolve, reject) => {
        axios
            .request({
                url: url,
                method: config.method || 'get',
                data: config.data,
                headers: {
                    ...config.headers,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error.response)
            })
    })
}

export function isWithinXMonths(date: Date, months: number) {
    const now = new Date()
    const sixMonthsAgo = new Date()
    sixMonthsAgo.setMonth(now.getMonth() - months)

    return date >= sixMonthsAgo && date <= now
}

export function isValidDateString(dateString: string) {
    return !isNaN(Date.parse(dateString))
}

export function hasDuplicates(array: any[]) {
    return new Set(array).size !== array.length
}

export function removeAllValuesFromArray<DT>(arr: DT[], value: DT): DT[] {
    var i = 0
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1)
        } else {
            ++i
        }
    }
    return arr
}

export function isValidJSON(str: any) {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}

export function combineObjects(obj1: any, obj2: any) {
    let combinedObj: any = {}

    for (let key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            // Check if the value in obj1 is empty and if the same key exists in obj2
            if (
                (obj1[key] === '' || obj1[key] == null) &&
                obj2.hasOwnProperty(key)
            ) {
                combinedObj[key] = obj2[key] // Use the value from obj2
            } else {
                combinedObj[key] = obj1[key] // Use the value from obj1
            }
        }
    }

    return combinedObj
}

export function convertParamsVariableString(
    string: string,
    additionalPropsArray: JSON[]
) {
    if (!additionalPropsArray?.length) return string

    let words = extractVariableWords(string)

    for (let i = 0; i < words.length; i++) {
        const word = words[i]

        let index: number = getIndexFromVariableWord(word, -1)
        if (index < 0) continue
        if (index > additionalPropsArray.length - 1) continue

        let additionalProps = additionalPropsArray[index]

        if (!additionalProps) continue

        let convertedWord = convertVariableWord(word, additionalProps)

        if (!convertedWord) {
            string = string.replace(word, '')
        } else {
            string = string.replace(word, convertedWord)
        }
    }

    return string
}

function convertVariableWord(
    word: string,
    additionalProps: any
): string | null {
    let plainWord = word.replace('{{', '').replace('}}', '').split('_')[1]
    let additionalPropValue = additionalProps[plainWord]
    if (!additionalPropValue) return null

    return additionalPropValue
}

function extractVariableWords(inputString: string) {
    const regex = /\{\{[^}]+\}\}/g

    // Use match() to find all matches in the inputString
    const matches = inputString.match(regex)

    return matches || [] // Return matches or an empty array if no matches are found
}

export function getIndexFromVariableWord(
    word: string,
    defaultIndex: number = 0
): number {
    word = word.replace('{{', '').replace('}}', '')
    let splitWord = word.split('_')

    //there has to be at least 1 _ (length 2 after split)
    if (splitWord.length <= 1) {
        return defaultIndex
    }

    let indexSPlitWord = splitWord[0]

    if (!indexSPlitWord) {
        return defaultIndex
    }

    let convertedWord: number = parseInt(indexSPlitWord, 10)

    if (isNaN(convertedWord)) {
        return defaultIndex
    }

    return convertedWord
}

export function combineSortFunctions(sortFunctions: Function[]) {
    return function (a_task: any, b_task: any) {
        for (let sortFunction of sortFunctions) {
            // Execute the current sort function with a_task and b_task
            let result = sortFunction(a_task, b_task)
            // If the result is non-zero, return it as it determines the order
            if (result !== 0) {
                return result
            }
        }
        // If all sort functions return 0, consider them equal
        return 0
    }
}

export function paginateArray(
    array: any[],
    paginationIndex: number,
    paginationAmount: number
) {
    const startIndex = paginationIndex * paginationAmount
    const endIndex = (paginationIndex + 1) * paginationAmount
    return array.slice(startIndex, endIndex)
}

export function calculateDurationObject(duration_seconds: number) {
    let unit = 's'
    let amount = duration_seconds

    if (amount >= 60) {
        unit = 'm'
        amount = amount / 60
    }
    if (amount >= 60) {
        unit = 'h'
        amount = amount / 60
    }
    if (amount >= 24) {
        unit = 'd'
        amount = amount / 24
    }
    if (amount >= 7) {
        unit = 'w'
        amount = amount / 7
    }
    if (amount >= 52) {
        unit = 'y'
        amount = amount / 52
    }

    return {
        unit: unit,
        amount: amount,
    }
}

export function hasDecimals(number: number): boolean {
    return number % 1 !== 0
}

export function makeIndexArray(n: number) {
    return Array.from({ length: n }, (_, index) => index)
}

type PopupCenterProps = {
    url: string
    title: string
    w: number
    h: number
}
export function openWindowCenter({ url, title, w, h }: PopupCenterProps) {
    // Fixes dual-screen position
    const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width

    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height

    // Calculate the zoom factor, but we won't apply this to positioning
    const systemZoom = window.screen.availWidth / width

    // Correctly calculate the left and top positions for centering
    const left = (width - w) / 2 + dualScreenLeft
    const top = (height - h) / 2 + dualScreenTop + 50

    // Open the new popup window with the calculated position, but apply zoom only to size
    const newWindow = window.open(
        url,
        title,
        `
        scrollbars=yes,
        width=${w},
        height=${h},
        top=${top},
        left=${left}
        `
    )

    // Focus the new window if possible
    if (newWindow?.focus) newWindow.focus()

    return newWindow
}

export function includesAny(original: any, checks: any[]): boolean {
    if (!original) return false
    let match: boolean = false
    if (checks !== undefined && checks.length > 0) {
        checks.map((check) => {
            if (original.includes(check)) {
                match = true
            }
        })
    }
    return match
}

export function includesAll(original: any, checks: any[]): boolean {
    if (!original) return false
    let match: boolean = true
    if (checks !== undefined && checks.length > 0) {
        checks.map((check) => {
            if (!original.includes(check)) {
                match = false
            }
        })
    }
    return match
}

export function isMobileSize() {
    return window.innerWidth <= 768
}

export function confirmAction(
    action: Function,
    props: any,
    message?: string,
    icon?: 'info' | 'question' | 'warning'
) {
    Swal.fire({
        title: 'Are you sure?',
        text: message || 'This action is permanent and cannot be undone',
        icon: icon || 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            action(props)
        }
    })
}

export function truncateN(
    string: string,
    numberOfCharactersVisible: number
): string {
    if (!string) return string

    // If the string is shorter or equal to the number of visible characters * 2, return the original string
    if (string.length <= numberOfCharactersVisible * 2) {
        return string
    }

    // Get the beginning and end parts of the string
    const start = string.slice(0, numberOfCharactersVisible)
    const end = string.slice(-numberOfCharactersVisible)

    // Combine with ellipsis in between
    return `${start}...${end}`
}

export function swalError(message: string) {
    Swal.fire({
        title: 'Error',
        text: 'Something went wrong. ' + message,
        icon: 'error',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        allowOutsideClick: false,
        heightAuto: false,
    })
}
