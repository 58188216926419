import { useState } from 'react'

const useModal = () => {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    function toggle() {
        setIsOpen(!isOpen)
    }

    return {
        isOpen,
        closeModal,
        openModal,
        toggle,
    }
}

export default useModal
