import React, { useEffect, useRef, useState } from 'react'

import Button from '../../Button'
import Card from '../../Card'
import SuperadminDashboard from './SuperadminDashboard'
import SuperadminTaskAdjuster from './SuperadminTaskAdjuster'
import ConversionRateAdjuster from './ConversionRateAdjuster'
import {
    GET_SUPERADMIN_STATS_URL,
    GET_SUPERADMIN_TASKS_URL,
    GETTASKCATEGORIES_URL,
    UPLOAD_USER_CSV,
} from '../../../library/urls'
import { Request, swalError } from '../../../library/functions'
import CommunitiesDashboard from './CommunitiesDashboard'
import { getEthereumSignature } from '../../../library/signature'
import Swal from 'sweetalert2'

const superadmin_tabs = [
    { id: 'dashboard', display: 'Dashboard' },
    { id: 'task-adjuster', display: 'Task Adjuster' },
    { id: 'conversion-rates', display: 'Conversion Rates' },
    { id: 'communities', display: 'Communities' },
]

export default function SuperadminDashboardLayout({ user }) {
    const [superadminStats, setSuperadminStats] = useState(null)
    const [taskCategories, setTaskCategories] = useState(null)
    const [superadminTasks, setSuperadminTasks] = useState(null)

    const [superadminTabSelected, setSuperadminTabSelected] = useState(
        superadmin_tabs[0]
    )

    useEffect(() => {
        getSuperadminStats(setSuperadminStats)
        getTaskCategories(setTaskCategories)

        const params = new URLSearchParams(window.location.search)
        const superadminRoute = params.get('superadmin-route')

        if (superadminRoute === 'task-adjuster') {
            setSuperadminTabSelected({
                id: 'task-adjuster',
                display: 'Task Adjuster',
            })
        }
        if (superadminRoute === 'communities') {
            setSuperadminTabSelected({
                id: 'communities',
                display: 'Communities',
            })
        }
    }, [])

    return (
        <div className="h-full max-h-full gap-3 max-w-[1500px] mx-auto w-full flex flex-col lg:p-4">
            <div className="flex justify-between items-center">
                <SuperadminDashboardTabSelector
                    tabs={superadmin_tabs}
                    tabSelected={superadminTabSelected}
                    setTabSelected={setSuperadminTabSelected}
                />
                <AddUsersButton />
            </div>
            <SuperadminDashboardTab
                tab={superadminTabSelected}
                user={user}
                superadminTasks={superadminTasks}
                superadminStats={superadminStats}
                taskCategories={taskCategories}
            />
        </div>
    )
}

function SuperadminDashboardTab(props) {
    if (!props?.tab) return <></>
    switch (props.tab.id) {
        case 'dashboard':
            return <SuperadminDashboard {...props} />
        case 'task-adjuster':
            return <SuperadminTaskAdjuster {...props} />
        case 'conversion-rates':
            return <ConversionRateAdjuster {...props} />
        case 'communities':
            return <CommunitiesDashboard {...props} />

        default:
            return <SuperadminDashboard {...props} />
    }
}

function SuperadminDashboardTabSelector({ tabs, tabSelected, setTabSelected }) {
    if (!tabs?.length) return <></>

    return (
        <div className="flex items-center justify-start gap-2">
            {tabs.map((tab) => {
                const isSelected = tab?.id === tabSelected?.id

                return (
                    <Card className={' !p-1'} key={tab.id}>
                        <Button
                            variant="ghost"
                            className={`w-full !rounded-none !py-2 ${
                                isSelected ? '!text-primary' : ''
                            }`}
                            onClick={() => {
                                setTabSelected(tab)
                            }}
                        >
                            {tab.display}
                        </Button>
                    </Card>
                )
            })}
        </div>
    )
}

async function getSuperadminStats(setSuperadminStats) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: GET_SUPERADMIN_STATS_URL,
        method: 'get',
        data: {
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            const superadminStats = response?.superadminStats
            if (!superadminStats) return
            setSuperadminStats(superadminStats)
        })
        .catch((err) => {
            console.log(err)
        })
}

async function getTaskCategories(setTaskCategories, wallet) {
    const config = {
        url: GETTASKCATEGORIES_URL,
        method: 'get',
    }

    Request(config)
        .then((response) => {
            const taskCategories = response?.taskCategories
            if (!taskCategories) return
            setTaskCategories(taskCategories)
        })
        .catch((err) => {
            console.log(err)
        })
}

function AddUsersButton() {
    const fileInputRef = useRef(null)

    // Trigger the hidden <input> to open
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    // Runs when the user selects a file
    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (!file) return // user canceled

        // We only allow .csv, but do a quick check anyway
        if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
            Swal.fire('Error', 'Please select a valid CSV file.', 'error')
            return
        }

        // Ask user to confirm, then upload
        confirmAction(uploadCsv, {
            file,
            confirmActionmessage: 'Are you sure you want to upload this CSV?',
        })
    }

    // The actual upload logic (called only after user confirms)
    const uploadCsv = async ({ file }) => {
        try {
            const { signature, wallet } = await getEthereumSignature()
            if (!signature)
                return swalError(
                    'No signature available. Sign in with metamask'
                )

            const formData = new FormData()
            formData.append('file', file)
            formData.append('access_wallet', wallet)
            formData.append('access_signature', signature)

            const config = {
                url: UPLOAD_USER_CSV,
                method: 'post',
                data: formData,
            }

            Request(config)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            title: 'Success!',
                            text: response.message || 'Successfully created',
                            icon: 'success',
                            confirmButtonText: 'Continue',
                            cancelButtonText: 'Cancel',
                            showCancelButton: false,
                            allowOutsideClick: false,
                            heightAuto: false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong. ' + response.message,
                            icon: 'error',
                            confirmButtonText: 'Continue',
                            cancelButtonText: 'Cancel',
                            showCancelButton: false,
                            allowOutsideClick: false,
                            heightAuto: false,
                        }).then(() => {
                            //hard reload
                        })
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong. Error: ' + err.message,
                        icon: 'error',
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        heightAuto: false,
                    }).then(() => {
                        //hard reload
                    })
                })
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Error',
                `There was a problem uploading the file: ${error.message}`,
                'error'
            )
        }
    }

    return (
        <>
            <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
            />
            <Button variant="border" onClick={handleButtonClick}>
                Import users (.csv)
            </Button>
        </>
    )
}

function confirmAction(action, props) {
    Swal.fire({
        title: 'Are you sure?',
        text:
            props.confirmActionmessage ||
            'This action is permanent and cannot be undone',
        icon: 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            action(props)
        }
    })
}
