import React from 'react'
import { classNames } from '../utils/helper'

export default function MaxWidthContainer({
    children,
    size,
    backgroundColor,
    bgImage,
    className,
    bgOpacity = 100,
    ...props
}) {
    const maxWidth = getMaxWidth(size)

    return (
        <div
            className={classNames(
                'h-full flex justify-center relative w-screen',
                className
            )}
            {...props}
        >
            <div
                className="w-full z-10 h-full"
                style={{ maxWidth: `${maxWidth}px` }}
            >
                {children}
            </div>
            <img
                src={bgImage}
                alt=""
                className="absolute 4xl:object-contain object-cover w-full h-full top-0 left-0 -z-10"
                style={{
                    opacity: `${bgOpacity}%`,
                }}
            ></img>
            <div
                className="absolute object-cover w-full min-h-full top-0 left-0 -z-20"
                style={{ background: backgroundColor }}
            ></div>
        </div>
    )
}

function getMaxWidth(size) {
    switch (size) {
        case 'sm':
            return 600
        case 'md':
            return 800
        case 'lg':
            return 1000
        case 'xl':
            return 1200
        case '2xl':
            return 1400

        default:
            return 600
    }
}
