import React, { useState } from 'react'
import Button from '../../Button'
import { BsArrowLeftShort } from 'react-icons/bs'

export default function BackArrowContainer({ children, action }) {
    const [hovering, setHovering] = useState(false)

    return (
        <div
            className="flex items-center gap-2  cursor-pointer lg:mb-6 mb-4"
            onClick={action}
        >
            <Button
                variant="ghost"
                className={`!px-1.5 !py-1.5 ${hovering ? 'bg-white/10' : ''}`}
            >
                <BsArrowLeftShort className="h-[20px] w-[20px] -ml-[1px]" />
            </Button>
            <h2
                className="text-white text-[18px] m-0 -mt-[1px] tracking-wider font-mono"
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                {children}
            </h2>
        </div>
    )
}
