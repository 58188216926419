import loader from '../assets/img/loader.gif'

const Loader = ({ width = 34, message }) => {
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <img src={loader} width={width} alt="loader gif" />
            {message && (
                <p className="text-neutral-500 text-sm mt-4">{message}</p>
            )}
        </div>
    )
}

export default Loader
