import React from 'react'
import Input from './Input'

export default function InputSection({
    value,
    setValue,
    title,
    explainer,
    disabled,
    className,
}) {
    function handleOnChange(e) {
        let new_value = e.target.value
        setValue(new_value)
    }

    return (
        <div
            className="cursor-default"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <p className="text-neutral-400 mb-1.5 text-sm">
                {title}
                <span className="text-xs ml-2 text-neutral-500">
                    {explainer}
                </span>
            </p>
            <Input
                variant="borderinput"
                className={` !py-1.5 !px-3 ${className}`}
                value={value}
                id="maxAmountOfCompletions"
                onChange={handleOnChange}
                disabled={disabled}
            />
        </div>
    )
}
