import React, { useEffect, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { FaDiscord } from 'react-icons/fa6'
import { LoginButton } from '@telegram-auth/react'

import {
    hasDuplicates,
    isWithinXMonths,
    Request,
} from '../../../library/functions'
import { RenderSVG } from '../../../library/globalComponents'
import {
    CHECKUSERNAMEEXISTS_URL,
    DISCORD_AUTH_URL,
    GETUSER_URL,
    INSTAGRAM_AUTH_URL,
    TELEGRAM_AUTH_CALLBACK_URL,
    TELEGRAM_AUTH_URL,
    TWITTER_AUTH_URL,
    UPDATEUSER_URL,
    UPDATEUSERNAME_URL,
    YOUTUBE_AUTH_URL,
} from '../../../library/urls'
import { useAuth } from '../../../utils/hooks/auth'
import Button from '../../Button'
import Card from '../../Card'
import Input from '../../Input'
import Loader from '../../Loader'
import profileSettingsIcon from './../../../assets/svg/icons/profile-settings-icon.svg'
import SocialAccountsIcon from './../../../assets/svg/icons/social-account-icon.svg'
import walletAddressesIcon from './../../../assets/svg/icons/wallet-address-icon.svg'

import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import BackArrowContainer from '../layout/BackArrowContainer'
import { useQuestplatformUser } from '../../../utils/hooks/useQuestplatformUser'
export default function ProfileSettingsGrid() {
    const navigate = useNavigate()
    const { questplatformUser } = useQuestplatformUser()

    const [selectedRoute, setSelectedRoute] = useState('profile-settings')

    function handleSetSelectedRoute(value) {
        setSelectedRoute(value)
    }

    function handleToggleProfileSettingsOpen() {
        navigate('/novus')
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const profileSettingsRoute = params.get('state')

        if (!profileSettingsRoute) return
        if (
            profileSettingsRoute === 'profile-settings' ||
            profileSettingsRoute === 'wallet-addresses' ||
            profileSettingsRoute === 'social-accounts'
        )
            setSelectedRoute(profileSettingsRoute)
    }, [window.location.search])

    return (
        <div className="lg:grid flex flex-col grid-cols-[auto_1fr] h-full gap-x-[240px] gap-y-[30px]">
            <EditProfileMenu
                selectedRoute={selectedRoute}
                handleSetSelectedRoute={handleSetSelectedRoute}
                handleToggleProfileSettingsOpen={
                    handleToggleProfileSettingsOpen
                }
            />

            <ProfileMenuItems
                selectedRoute={selectedRoute}
                user={questplatformUser}
            />
        </div>
    )
}

function EditProfileMenu({
    selectedRoute,
    handleSetSelectedRoute,
    handleToggleProfileSettingsOpen,
}) {
    return (
        <div className="w-full max-w-[400px]">
            <BackArrowContainer action={handleToggleProfileSettingsOpen}>
                EDIT PROFILE
            </BackArrowContainer>

            <div className="flex flex-col w-full">
                <ProfileMenuItem
                    selectedRoute={selectedRoute}
                    handleSetSelectedRoute={handleSetSelectedRoute}
                    route="profile-settings"
                    display={'PROFILE SETTINGS'}
                    icon={profileSettingsIcon}
                />
                <ProfileMenuItem
                    selectedRoute={selectedRoute}
                    handleSetSelectedRoute={handleSetSelectedRoute}
                    route="wallet-addresses"
                    display={'WALLET ADDRESSES'}
                    icon={walletAddressesIcon}
                />
                <ProfileMenuItem
                    selectedRoute={selectedRoute}
                    handleSetSelectedRoute={handleSetSelectedRoute}
                    route="social-accounts"
                    display={'SOCIAL ACCOUNTS'}
                    icon={SocialAccountsIcon}
                />
            </div>
        </div>
    )
}
function ProfileMenuItem({
    selectedRoute,
    handleSetSelectedRoute,
    route,
    display,
    icon,
}) {
    const isSelected = selectedRoute === route
    return (
        <Button
            variant={isSelected ? 'primary' : 'ghost'}
            className={` my-2 text-left  !justify-start !gap-5 !py-3.5`}
            onClick={() => {
                handleSetSelectedRoute(route)
            }}
        >
            {icon && (
                <RenderSVG
                    id={route}
                    width={20}
                    height={20}
                    src={icon}
                    color={'currentColor'}
                    className="w-[20px] h-[20px]"
                />
            )}
            {display}
        </Button>
    )
}

function ProfileMenuItems({ selectedRoute, user }) {
    if (!user)
        return (
            <div className={'p-8 h-full flex items-center justify-center'}>
                <Loader />
            </div>
        )
    switch (selectedRoute) {
        case 'profile-settings':
            return <ProfileSettings user={user} />
        case 'wallet-addresses':
            return <WalletAddresses user={user} />
        case 'social-accounts':
            return <SocialAccounts user={user} />

        default:
            break
    }
}

function ProfileSettings({ user }) {
    const [usernameInput, setUsernameInput] = useState(user.username)
    const [usernameExists, setUsernameExists] = useState(false)

    const isOriginalUsername = usernameInput === user.username

    function handleSetUsernameExists(value) {
        setUsernameExists(value)
    }

    useEffect(() => {
        checkUsernameExists(usernameInput, handleSetUsernameExists)
    }, [usernameInput])

    const disabled = isWithinXMonths(new Date(user.usernameChangedDate), 6)
    return (
        <div>
            <h3 className="text-xl text-white lg:mb-10 mb-4 -mt-1 font-mono">
                PROFILE SETTINGS
            </h3>
            <p className="text-white mb-2">Username</p>
            <Input
                variant="borderinput"
                value={usernameInput}
                disabled={disabled}
                onChange={(e) => {
                    setUsernameInput(e.target.value)
                }}
                className={
                    usernameExists && !isOriginalUsername && 'border-red-500'
                }
            />
            {usernameExists && !isOriginalUsername && (
                <p className="text-xs text-red-500 mt-2">
                    Username already exists
                </p>
            )}
            <p className="text-xs text-neutral-500 mt-2">
                Changes are allowed every 6 months.
            </p>

            {disabled && (
                <p className="text-xs text-neutral-500 mt-2">
                    Last change:{' '}
                    {new Date(user.usernameChangedDate).toLocaleDateString()}
                </p>
            )}
            <Button
                className="mt-8"
                disabled={disabled || usernameExists || isOriginalUsername}
                onClick={() => {
                    confirmAction(saveUsername, {
                        wallet: user.wallet,
                        username: usernameInput,
                    })
                }}
            >
                Save Changes
            </Button>
        </div>
    )
}
function WalletAddresses({ user }) {
    const initialUserAddresses = user.walletAddresses

    return (
        <div>
            <h3 className="text-xl text-white lg:mb-10 mb-4  -mt-1 font-mono">
                WALLET ADDRESSES
            </h3>
            <p className="text-white mb-2">Main Wallet</p>
            <Input
                key={'newAddress'}
                variant="borderinput"
                value={user.wallet}
                disabled={true}
            />

            {/* <AddressList user={user} initialUserAddresses={initialUserAddresses} /> */}
        </div>
    )
}

function AddressList({ user, initialUserAddresses }) {
    const [userAddresses, setUserAddresses] = useState(initialUserAddresses)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        if (!Array.isArray(userAddresses)) return

        //check if there are duplicates
        if (hasDuplicates(userAddresses)) {
            setErrorMessage('Wallet address already added')
            let newAddresses = [...userAddresses]
            newAddresses = [...new Set(newAddresses)]
            setUserAddresses(newAddresses)
        }
    }, [userAddresses])

    return (
        <div className="">
            <p className="text-white mb-2 mt-5">Address list</p>
            <form
                className="flex flex-col gap-2"
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                key="userAddresses"
            >
                {Array.isArray(userAddresses) &&
                    userAddresses?.map((userAddress, index) => {
                        return (
                            <Input
                                key={index}
                                variant="borderinput"
                                value={userAddress}
                                onChange={(e) => {
                                    let newAddressValue = e.target.value
                                    let newUserAddresses = [...userAddresses]

                                    if (newAddressValue === '') {
                                        //delete
                                        newUserAddresses.splice(index, 1)
                                    } else {
                                        //update
                                        newUserAddresses[index] = e.target.value
                                    }
                                    setUserAddresses(newUserAddresses)
                                    setErrorMessage(null)
                                }}
                            />
                        )
                    })}
                <Input
                    key={'New Address'}
                    variant="borderinput"
                    placeholder={'newAddress'}
                    onChange={(e) => {
                        let newUserAddresses = Array.isArray(userAddresses)
                            ? [...userAddresses]
                            : []
                        let newAddress = e.target.value
                        newUserAddresses.push(newAddress)
                        setUserAddresses(newUserAddresses)
                        setErrorMessage(null)
                        e.target.value = ''
                    }}
                />
                <div className="flex justify-between items-center mt-3">
                    <p className="text-red-600 text-sm">{errorMessage}</p>
                    <Button
                        disabled={initialUserAddresses === userAddresses}
                        onClick={() => {
                            saveUserAddresses(user.wallet, userAddresses)
                        }}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    )
}

function saveUserAddresses(wallet, userAddresses) {
    if (!wallet || !userAddresses) return

    const config = {
        url: UPDATEUSER_URL,
        method: 'post',
        data: {
            wallet: wallet,
            updateParams: JSON.stringify({ walletAddresses: userAddresses }),
        },
    }

    Request(config)
        .then((response) => {
            if (response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully updated',
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=wallet-addresses`
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong. ' + response.message,
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=wallet-addresses`
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Error: ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {})
        })
}

function SocialAccounts({ user }) {
    const [showTelegramLoginButton, setShowTelegramLoginButton] =
        useState(false)
    let userWallet = user.wallet

    return (
        <div className="flex flex-col gap-6">
            <h3 className="text-xl text-white tracking-wider lg:mb-10 mb-4  -mt-1 font-mono">
                LINK YOUR SOCIAL ACCOUNTS
            </h3>
            <div className="flex flex-col gap-[30px]">
                <SocialLoginButton
                    name={'TWITTER'}
                    loggedIn={!!user.twitterUserId}
                    username={user.twitterUsername}
                    icon={'/assets/icons/twitterIcon.svg'}
                    loginAction={() => handleTwitterLogin(userWallet)}
                    logoutAction={() => handleTwitterLogout(userWallet)}
                />
                <SocialLoginButton
                    name={'DISCORD'}
                    loggedIn={!!user.discordUsername}
                    username={user.discordUsername}
                    icon={'/assets/icons/discordIcon.svg'}
                    loginAction={() => handleDiscordLogin(userWallet)}
                    logoutAction={() => handleDiscordLogout(userWallet)}
                />
                {/* <SocialLoginButton
                    name={'YOUTUBE'}
                    loggedIn={!!user.youtubeUserId}
                    username={user.youtubeUsername}
                    icon={'/assets/icons/youtubeIcon.svg'}
                    loginAction={() => handleYoutubeLogin(userWallet)}
                    logoutAction={() => handleYoutubeLogout(userWallet)}
                /> */}
                <SocialLoginButton
                    name={'INSTAGRAM'}
                    loggedIn={!!user.instagramUsername}
                    username={user.instagramUsername}
                    icon={'/assets/icons/instagramIcon.svg'}
                    loginAction={() => handleInstagramLogin(userWallet)}
                    logoutAction={() => handleInstagramLogout(userWallet)}
                />
                {showTelegramLoginButton && (
                    <LoginButton
                        botUsername={'questplatformVerifierBot'}
                        onAuthCallback={(data) => {
                            // call your backend here to validate the data and sign in the user
                            handleTelegramAuthCallback(data, userWallet)
                        }}
                        buttonSize="large" // "large" | "medium" | "small"
                        cornerRadius={5} // 0 - 20
                        showAvatar={true} // true | false
                        lang="en"
                    />
                )}

                <SocialLoginButton
                    name={'TELEGRAM'}
                    loggedIn={!!user.telegramId}
                    username={user.telegramUsername}
                    icon={'/assets/icons/telegramIcon.svg'}
                    logoutAction={() => handleTelegramLogout(userWallet)}
                    loginAction={() =>
                        setShowTelegramLoginButton(!showTelegramLoginButton)
                    }
                />
            </div>
        </div>
    )
}

function SocialLoginButton({
    loggedIn,
    name,
    icon,
    logoutAction,
    loginAction,
    username,
    customOnClick,
}) {
    const [hovering, setHovering] = useState(false)

    let message = `CONNECT ${name} ACCOUNT`
    if (loggedIn) message = `${name} ACCOUNT CONNECTED`
    if (hovering && loggedIn) message = `DISCONNECT`

    function handleOnClick() {
        if (loggedIn) {
            logoutAction()
        } else {
            loginAction()
        }
    }

    return (
        <Button
            variant="border"
            onClick={customOnClick || handleOnClick}
            className={`h-[50px] ${loggedIn ? 'opacity-50' : ''}`}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            {icon && (!loggedIn || !hovering) && (
                <RenderSVG
                    id={name}
                    width={20}
                    height={20}
                    src={icon}
                    color={'currentColor'}
                    className="w-[20px] h-[20px]"
                />
            )}
            {message}
            {username && !hovering && ` • ${username}`}
        </Button>
    )
}

function DiscordLoginButton({ user }) {
    const [discordLoggedHovering, setDiscordLoggedHovering] = useState(false)

    const userWallet = user.wallet

    if (user.discordUsername)
        return (
            <div
                className=""
                onMouseEnter={() => {
                    setDiscordLoggedHovering(true)
                }}
                onMouseLeave={() => {
                    setDiscordLoggedHovering(false)
                }}
            >
                <Button
                    variant="secondary"
                    className="max-w-[230px] w-full"
                    onClick={() => {
                        handleDiscordLogout(userWallet)
                    }}
                >
                    <FaDiscord className="" />
                    {discordLoggedHovering ? 'Log out' : 'Logged in'}
                </Button>
            </div>
        )
    return (
        <Button
            onClick={() => {
                handleDiscordLogin(userWallet)
            }}
            className="!bg-[#5865F2] text-white hover:!bg-[#8a93f5]"
        >
            <FaDiscord className="" />
            Connect with Discord
        </Button>
    )
}

function handleTwitterLogout(wallet) {
    updateUserLogin(
        {
            twitterUsername: null,
            twitterUserId: null,
            twitterOauthAccessToken: null,
            twitterOauthAccessTokenSecret: null,
        },
        wallet,
        'logged out'
    )
}
function handleTwitterLogin(userWallet) {
    const twitterURL = TWITTER_AUTH_URL + `?wallet=${userWallet}`
    let newWindow = window.open(
        twitterURL,
        '_blank',
        'location=yes,height=650,width=600,scrollbars=yes,status=yes'
    )

    function onWindowClose() {
        //hard reload
        window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
    }

    if (newWindow) {
        // Create a function to check if the new window is closed
        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed) // Stop checking
                onWindowClose() // Run the function when the window closes
            }
        }, 500) // Check every 500ms
    } else {
        console.log('Failed to open the new window')
    }
}
function handleDiscordLogout(wallet) {
    updateUserLogin({ discordUsername: null }, wallet, 'logged out')
}
function handleDiscordLogin(userWallet) {
    const discordURL = DISCORD_AUTH_URL + `&state=${userWallet}`
    let newWindow = window.open(
        discordURL,
        '_blank',
        'location=yes,height=650,width=600,scrollbars=yes,status=yes'
    )

    function onWindowClose() {
        //hard reload
        window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
    }

    if (newWindow) {
        // Create a function to check if the new window is closed
        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed) // Stop checking
                onWindowClose() // Run the function when the window closes
            }
        }, 500) // Check every 500ms
    } else {
        console.log('Failed to open the new window')
    }
}

function handleYoutubeLogout(wallet) {
    updateUserLogin(
        { youtubeUserId: null, youtubeUsername: null },
        wallet,
        'logged out'
    )
}
function handleYoutubeLogin(userWallet) {
    const youtubeURL = YOUTUBE_AUTH_URL + `&state=${userWallet}`

    let newWindow = window.open(
        youtubeURL,
        '_blank',
        'location=yes,height=650,width=600,scrollbars=yes,status=yes'
    )

    function onWindowClose() {
        // Hard reload the page after window closes
        window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
    }

    if (newWindow) {
        // Create a function to check if the new window is closed
        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed) // Stop checking
                onWindowClose() // Run the function when the window closes
            }
        }, 500) // Check every 500ms
    } else {
        console.log('Failed to open the new window')
    }
}

function handleInstagramLogout(wallet) {
    updateUserLogin({ instagramUsername: null }, wallet, 'logged out')
}
async function handleInstagramLogin(userWallet) {
    const { value: username, ...swal_info } = await Swal.fire({
        title: 'Input username',
        input: 'text',
        inputLabel: 'Your Instagram username',
        inputPlaceholder: 'Enter your Instagram username',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    })
    if (!swal_info.isConfirmed) return
    if (!username) {
        return Swal.fire({
            title: 'Invalid username',
            text: `Ìnvalid username: ${username}`,
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            showCancelButton: false,
            allowOutsideClick: false,
            heightAuto: false,
        })
    }

    const instagramURL =
        INSTAGRAM_AUTH_URL + `?username=${username}&state=${userWallet}`

    let newWindow = window.open(
        instagramURL,
        '_blank',
        'location=yes,height=650,width=600,scrollbars=yes,status=yes'
    )

    function onWindowClose() {
        // Hard reload the page after window closes
        window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
    }

    if (newWindow) {
        // Create a function to check if the new window is closed
        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed) // Stop checking
                onWindowClose() // Run the function when the window closes
            }
        }, 500) // Check every 500ms
    } else {
        console.log('Failed to open the new window')
    }
}

function handleTelegramLogout(wallet) {
    updateUserLogin(
        { telegramUsername: null, telegramId: null },
        wallet,
        'logged out'
    )
}
async function handleTelegramLogin(userWallet) {
    //the telegram login script
    let telegram_login_button = document.getElementById(
        'telegram-login-questplatformVerifierBot'
    )
    let src = telegram_login_button.src

    if (!src) return

    let newWindow = window.open(
        src,
        '_blank',
        'location=yes,height=650,width=600,scrollbars=yes,status=yes'
    )

    function onWindowClose() {
        // Hard reload the page after window closes
        window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
    }

    if (newWindow) {
        // Create a function to check if the new window is closed
        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed) // Stop checking
                onWindowClose() // Run the function when the window closes
            }
        }, 500) // Check every 500ms
    } else {
        console.log('Failed to open the new window')
    }
}

function updateUserLogin(updateParams, wallet, message = 'logged in') {
    if (!updateParams || !wallet)
        return console.log('No updateParams or wallet')

    const config = {
        url: UPDATEUSER_URL,
        method: 'post',
        data: {
            wallet: wallet,
            updateParams: updateParams,
        },
    }

    console.log(config)

    Request(config)
        .then((response) => {
            if (response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: `Successfully ${message}`,
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong. ' + response.message,
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Error: ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {})
            console.log(err)
        })
}

function updateUsernames(wallet, formData) {
    if (!wallet || !formData) return

    let updateParams = JSON.stringify({
        discordUsername: formData.get('discordUsername'),
        mediumUsername: formData.get('mediumUsername'),
    })

    const config = {
        url: UPDATEUSER_URL,
        method: 'post',
        data: {
            wallet: wallet,
            updateParams: updateParams,
        },
    }

    Request(config)
        .then((response) => {
            if (response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully updated',
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong. ' + response.message,
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Error: ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {})
        })
}

function getUser(userWallet, setUser) {
    if (!userWallet) return

    const config = {
        url: GETUSER_URL,
        method: 'get',
        //with editing only provide data that needs changing
        data: {
            wallet: userWallet,
        },
    }

    Request(config)
        .then((response) => {
            const user = response?.user
            if (!user) return
            setUser(user)
        })
        .catch((err) => {
            console.log(err)
        })
}

function saveUsername({ wallet, username }) {
    if (!username) {
        Swal.fire({
            title: 'Invalid Username',
            text: 'The username you entered is invalid',
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            showCancelButton: false,
            allowOutsideClick: false,
            heightAuto: false,
        })
        return
    }

    const config = {
        url: UPDATEUSERNAME_URL,
        method: 'post',
        data: { wallet: wallet, newUsername: username },
    }

    Request(config)
        .then((response) => {
            if (response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully updated',
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong. ' + response.message,
                    icon: 'error',
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    heightAuto: false,
                }).then(() => {
                    //hard reload
                    window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong. Error: ' + err.message,
                icon: 'error',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            }).then(() => {})
        })
}

function checkUsernameExists(username, handleSetUsernameExists) {
    if (!username) return

    const config = {
        url: CHECKUSERNAMEEXISTS_URL,
        method: 'get',
        data: { username: username },
    }

    Request(config)
        .then((response) => {
            if (!response?.success) return
            if (response.usernameExists !== undefined) {
                handleSetUsernameExists(response.usernameExists)
            }
        })
        .catch((err) => {})
}

function confirmAction(action, props) {
    Swal.fire({
        title: 'Are you sure?',
        text: 'This action is permanent and cannot be undone',
        icon: 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            action(props)
        }
    })
}

async function handleTelegramAuthCallback(data, wallet) {
    let id = data.id
    let first_name = data.first_name
    let last_name = data.last_name
    let username = data.username
    let auth_date = data.auth_date
    let hash = data.hash

    if (!id || !wallet) return

    const config = {
        url: TELEGRAM_AUTH_URL,
        method: 'get',
        data: {
            id: id,
            username: username,
            wallet: wallet,
        },
    }

    console.log('init telegram auth')

    await Request(config)
        .then((res) => {
            console.log(res)
            //hard reload
            window.location.href = `${window.location.origin}${window.location.pathname}?state=social-accounts`
        })
        .catch((err) => {
            console.log(`Telegram handle callback error: `)
            console.log(err)
        })
}
