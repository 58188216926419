import React from 'react'
import { classNames } from '../utils/helper'

export const InputVariants = {
    PRIMARY: 'primary',
    NOINPUT: 'noinput',
    BORDERINPUT: 'borderinput',
    WHITE: 'white',
}

const INPUT_STYLES = {
    [InputVariants.PRIMARY]: ` gap-4 bg-white shadow-[0px_3px_0px_0px] shadow-gray-200 text-[15px] px-6 py-4 rounded-lg w-full placeholder-neutral-500`,
    [InputVariants.NOINPUT]:
        ' gap-4 bg-none text-sm text-white px-6 py-4 rounded-lg w-full placeholder-neutral-500',
    [InputVariants.BORDERINPUT]:
        'gap-4 bg-white/5 backdrop-blur-sm text-sm text-white px-4 py-[11px] rounded-lg w-full border border-neutral-600 focus-within:border-white/50  ',
    [InputVariants.WHITE]:
        'text-sm !border-black outline-0 focus:outline-none focus:ring-0 py-2 px-3 rounded-lg bg-white',
}

export default function Input({
    children,
    onChange,
    placeholder,
    id,
    defaultValue,
    variant = InputVariants.BORDERINPUT,
    className,
    disabled,
    disableInput,
    title,
    prefix,
    suffix,
    addedValueRender,
    ...props
}) {
    return (
        <div
            className={classNames(
                'flex flex-col gap-[0]',
                INPUT_STYLES[variant],
                className,
                `${disabled ? '!border-neutral-700/80' : ''}`
            )}
        >
            {title && (
                <p className="text-white/10 text-sm font-mono -mt-1">{title}</p>
            )}

            <div className="flex items-center gap-4">
                {prefix}
                {children}
                <input
                    onChange={onChange}
                    placeholder={placeholder}
                    className={`truncate h-full w-full border-transparent bg-transparent outline-none focus:border-transparent  focus:ring-0 p-0 text-sm placeholder-neutral-500 ${
                        disabled ? 'text-neutral-500' : ''
                    }`}
                    id={id}
                    name={id}
                    defaultValue={defaultValue}
                    disabled={disabled || disableInput}
                    {...props}
                />
                {suffix}
            </div>
        </div>
    )
}
