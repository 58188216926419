const LOCAL_URL = 'http://localhost:4000'
const API_URL = 'https://questapi.reformdao.com'

const PRODUCTION = true

const SERVER_URL = PRODUCTION ? API_URL : LOCAL_URL

export const BASE_URL = SERVER_URL

export const GETUSER_URL = SERVER_URL + '/users/get-user'
export const UPDATEUSER_URL = SERVER_URL + '/users/update-user'
export const UPDATEUSERNAME_URL = SERVER_URL + '/users/update-username'
export const CHECKUSERNAMEEXISTS_URL =
    SERVER_URL + '/users/check-username-exists'

export const GETTASKS_URL = SERVER_URL + '/tasks/get-tasks'
export const GETUSERTASKS_URL = SERVER_URL + '/tasks/get-user-tasks'
export const UPDATETASK_URL = SERVER_URL + '/tasks/update-task'
export const CREATETASK_URL = SERVER_URL + '/tasks/create-task'
export const DELETETASK_URL = SERVER_URL + '/tasks/delete-task'
export const GETTASKCATEGORIES_URL = SERVER_URL + '/tasks/get-task-categories'

export const GET_REFERRAL_LINK_URL = SERVER_URL + '/referrals/get-referral-link'
export const GET_USER_REFERRALSTATS_URL =
    SERVER_URL + '/referrals/get-user-referral-stats'
export const GET_REFERRALLINK_REFERRALSTATS_URL =
    SERVER_URL + '/referrals/get-referralLink-referral-stats'

export const STORE_IN_VAULT_URL = SERVER_URL + '/vault/store-in-vault'

export const GET_SUPERADMIN_STATS_URL = SERVER_URL + '/superadmin/get-stats'
export const GET_SUPERADMIN_TASKS_URL = SERVER_URL + '/superadmin/get-tasks'
export const GET_TASKENDPOINTOPTIONS_URL =
    SERVER_URL + '/superadmin/get-taskendpointoptions'
export const UPDATE_TASKENDPOINTOPTIONS_URL =
    SERVER_URL + '/superadmin/update-taskendpointoptions'
export const GET_SUPERADMIN_CONVERSION_RATES_URL =
    SERVER_URL + '/superadmin/get-tokenXPConversionRates'
export const CREATE_SUPERADMIN_CONVERSION_RATES_URL =
    SERVER_URL + '/superadmin/add-tokenXPConversionRate'
export const UPDATE_SUPERADMIN_CONVERSION_RATES_URL =
    SERVER_URL + '/superadmin/update-tokenXPConversionRate'
export const UPLOAD_USER_CSV = SERVER_URL + '/superadmin/add-users-csv'
export const GET_COMMUNITIES_URL = SERVER_URL + '/superadmin/get-communities'
export const UPDATE_COMMUNITY_URL = SERVER_URL + '/superadmin/update-community'
export const CREATE_COMMUNITY_URL = SERVER_URL + '/superadmin/create-community'
export const DELETE_COMMUNITY_URL = SERVER_URL + '/superadmin/delete-community'
export const GET_COMMUNITY_TASKS_URL =
    SERVER_URL + '/superadmin/get-community-tasks'
export const LINK_COMMUNITY_JOINTASK_URL =
    SERVER_URL + '/superadmin/link-joinTask'

export const GET_ADMIN_SETTING_URL =
    SERVER_URL + '/admin-settings/get-admin-setting'
export const UPDATE_ADMIN_SETTING_URL =
    SERVER_URL + '/admin-settings/set-admin-setting'

const DISCORD_AUTH_CALLBACK_URL = encodeURI(SERVER_URL + '/auth/discord')
const DISCORD_CLIENT_ID = '1314179725836423178'
export const DISCORD_AUTH_URL = `https://discord.com/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&response_type=code&redirect_uri=${DISCORD_AUTH_CALLBACK_URL}&scope=identify`

export const YOUTUBE_CLIENT_ID =
    '632895838002-mr91tf79hn9kku0d2a9hqnm0e4miu2gu.apps.googleusercontent.com' // Replace with your YouTube client ID
export const YOUTUBE_AUTH_CALLBACK_URL = encodeURI(SERVER_URL + '/auth/youtube')
export const YOUTUBE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${YOUTUBE_CLIENT_ID}&redirect_uri=${YOUTUBE_AUTH_CALLBACK_URL}&response_type=code&scope=https://www.googleapis.com/auth/youtube.readonly&access_type=online`

export const INSTAGRAM_AUTH_URL = SERVER_URL + '/auth/instagram'

export const TWITTER_AUTH_URL = SERVER_URL + '/auth/twitter/get-auth-url'

export const TELEGRAM_AUTH_CALLBACK_URL =
    '/novus-settings?state=social-accounts'
export const TELEGRAM_AUTH_URL = SERVER_URL + '/auth/telegram'
export const TELEGRAM_OAUTH_INIT_URL = `https://oauth.telegram.org/auth?bot_id=7268601244&origin=https%3A%2F%2Fd15xybm28dwkvy.cloudfront.net&embed=1&request_access=write&lang=en&return_to=https%3A%2F%2Fd15xybm28dwkvy.cloudfront.net%2Fquest-platform-settings%3Fstate%3Dsocial-accounts%26id%3D6260481298%26first_name%3DKoen%26last_name%3DVermeulen%26username%3Dkoenvermeulen%26auth_date%3D1729848182%26hash%3Ddf49150c2bb23bb046cef83f2b368eb27c07efa0b851d69c6a0dddad74f0f72b`
