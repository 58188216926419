import React, { useEffect, useState } from 'react'
import { Request } from '../../../library/functions'
import Card from '../../Card'
import Button from '../../Button'
import { LuAlertTriangle } from 'react-icons/lu'
import Swal from 'sweetalert2'
import useGetTaskEndpointOptions from '../functions/useGetTaskEndpointOptions'
import Loader from './../../../components/Loader'
import TaskEndpointOptionsList from './TaskEndpointOptionsList'

export default function SuperadminTaskAdjuster() {
    const { local_taskEndpointOptions } = useGetTaskEndpointOptions()

    const [warningSeen, setWarningSeen] = useState(
        sessionStorage.getItem('reformdao-warning-seen')
    )

    if (!local_taskEndpointOptions)
        return (
            <Card className={'h-full'}>
                <Loader message={'Loading taskEndpointOptions'} />
            </Card>
        )

    if (!warningSeen) return <WarningCard setWarningSeen={setWarningSeen} />

    return (
        <Card className={'h-full !p-5'}>
            <TaskEndpointExplainer />

            <TaskEndpointOptionsList
                initial_taskEndpointOptions={local_taskEndpointOptions}
            />
        </Card>
    )
}

function TaskEndpointExplainer() {
    return (
        <div className="mb-6">
            <p className="text-white ">Task Endpoint Options</p>
            <p className="text-neutral-500 text-sm mt-1.5">
                Task Endpoint Options are backend locations that can verify the
                tasks in the Task Dashboard. The edited values will only affect
                future tasks.
            </p>
        </div>
    )
}

function WarningCard({ setWarningSeen }) {
    return (
        <Card className={'h-full flex items-center justify-center '}>
            <div className="max-w-[500px] gap-3 flex flex-col items-center">
                <LuAlertTriangle className="text-orange-500 text-3xl mb-3" />
                <p className="-mb-1 text-white font-bold text-lg">Caution</p>
                <p className="text-neutral-400 text-center text-pretty">
                    Be extremely careful asjusting the task settings. These are
                    crucial for all task operations in the Quest Platform. This
                    dashboard is built for superadmins and is not dummyproof as
                    any change is permanent.
                    <br />
                    <br />
                    You can continue. Nothing happens still.
                </p>
                <div className="flex items-center justify-center mt-8">
                    <Button
                        variant="primary"
                        onClick={() => {
                            sessionStorage.setItem(
                                'reformdao-warning-seen',
                                'seen'
                            )
                            setWarningSeen('seen')
                        }}
                    >
                        I understand, continue
                    </Button>
                </div>
            </div>
        </Card>
    )
}
