import React from 'react'

export default function Card({ children, variant, className, ...props }) {
    if (variant === 'glass')
        return (
            <div
                className={`bg-white/10 backdrop-blur-md p-4 rounded-xl ${className}`}
                {...props}
            >
                {children}
            </div>
        )

    return (
        <div className={`bg-[#131313] p-3 ${className}`} {...props}>
            {children}
        </div>
    )
}
