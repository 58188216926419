import React from 'react'
import Loader from '../../components/Loader'
import SuperadminDashboard from '../../components/questPlatform/superadmin/SuperadminDashboardLayout'
import { useQuestplatformUser } from '../../utils/hooks/useQuestplatformUser'

export default function QuestPlatformSuperadmin({}) {
    const { questplatformUser } = useQuestplatformUser()
    const isSuperAdmin = questplatformUser?.role === 'superadmin' || null

    return (
        <div className="h-full bg-[#202020] mt-[4rem]">
            {isSuperAdmin ? (
                <SuperadminDashboard user={questplatformUser} />
            ) : isSuperAdmin === null ? (
                <div className="w-full h-full flex items-center justify-center">
                    <p className="text-white">
                        Log in with an Superadmin account
                    </p>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}
