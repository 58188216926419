import { useEffect, useState } from 'react'
import { useAuth } from './auth'
import { GETUSER_URL } from '../../library/urls'
import { Request } from '../../library/functions'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

export function useQuestplatformUser() {
    const [questplatformUser, setQuestplatformUser] = useState(null)

    //user
    const { address } = useWeb3ModalAccount()
    let user_address = address

    useEffect(() => {
        if (!user_address) setQuestplatformUser(null)
        getQuestplatformUser(user_address, setQuestplatformUser)
    }, [user_address])

    return { questplatformUser }
}

function getQuestplatformUser(user_address, setQuestplatformUser) {
    if (!user_address) return

    const urlParams = new URLSearchParams(window.location.search)
    const referral_id = urlParams.get('referral')

    const config = {
        url: GETUSER_URL,
        method: 'get',
        //with editing only provide data that needs changing
        data: {
            wallet: user_address,
            referral_id: referral_id,
        },
    }

    Request(config)
        .then((response) => {
            const user = response?.user
            if (!user) return

            setQuestplatformUser(user)
        })
        .catch((err) => {
            console.log(err)
        })
}
