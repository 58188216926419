import React from 'react'
import QuestplatformLayout from '../../components/questPlatform/layout/QuestplatformLayout'
import QuestPlatformReferralBody from '../../components/questPlatform/user/QuestPlatformReferralBody'

export default function QuestPlatformReferral() {
    return (
        <QuestplatformLayout>
            <QuestPlatformReferralBody />
        </QuestplatformLayout>
    )
}
