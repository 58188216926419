import { useEffect, useState } from 'react'
import { LuAlertTriangle, LuPlusCircle } from 'react-icons/lu'
import { Request, swalError } from '../../../library/functions'
import Button from '../../Button'
import Card from '../../Card'
import Loader from '../../Loader'
import {
    GET_ADMIN_SETTING_URL,
    GET_SUPERADMIN_CONVERSION_RATES_URL,
    UPDATE_ADMIN_SETTING_URL,
    UPDATE_SUPERADMIN_CONVERSION_RATES_URL,
} from '../../../library/urls'
import Input from '../../Input'
import Swal from 'sweetalert2'
import { getEthereumSignature } from '../../../library/signature'

const XPDollarRateKey = 'XPDollarRate'

export default function ConversionRateAdjuster() {
    const [conversionRates, setConversionRates] = useState(null)
    const [warningSeen, setWarningSeen] = useState(
        sessionStorage.getItem('reformdao-warning-seen-conversion-rates')
    )

    useEffect(() => {
        //on initial load
        getConversionRates(setConversionRates)
    }, [])

    if (conversionRates === null)
        return (
            <Card className={'h-full'}>
                <Loader message={'Loading conversionRates'} />
            </Card>
        )

    if (!warningSeen) return <WarningCard setWarningSeen={setWarningSeen} />

    return (
        <>
            <XPToDollarRateAdjuster />
            <Card className={'h-full !p-5'}>
                <TaskEndpointExplainer />

                <ConversionRatesList conversionRates={conversionRates} />
            </Card>
        </>
    )
}

function TaskEndpointExplainer() {
    return (
        <div className="mb-6">
            <p className="text-white ">Conversion Rates</p>
            <p className="text-neutral-500 text-sm mt-1.5">
                Conversion Rates are used to convert the questplatform XP into
                crypto tokens. Below you can add and edit conversion rates.
            </p>
        </div>
    )
}

function XPToDollarRateAdjuster() {
    const [XPToDollarRate, setXPToDollarRate] = useState(null)

    useEffect(() => {
        if (XPToDollarRate !== null) return
        getXPToDollarRate(setXPToDollarRate)
    }, [])

    function handleSave() {
        confirmAction(updateXPToDollarRate, { value: XPToDollarRate })
    }

    return (
        <Card className="mb-0">
            <div className="">
                <p className="text-white mb-1">
                    Token exchange rate{' '}
                    <span className="text-neutral-400 text-xs ml-1">
                        {'(1: $1 = 1XP | 10: $1 = 10XP)'}
                    </span>
                </p>
                <div className="flex items-stretch gap-3">
                    <Input
                        value={XPToDollarRate}
                        prefix={
                            <p className="text-nowrap text-neutral-400">
                                $1 ={' '}
                            </p>
                        }
                        onChange={(e) => setXPToDollarRate(e.target.value)}
                        suffix={
                            <p className="text-nowrap text-neutral-400">XP</p>
                        }
                    ></Input>
                    <Button onClick={handleSave}>Save</Button>
                </div>
            </div>
        </Card>
    )
}

function ConversionRatesList({ conversionRates }) {
    return (
        <div className="">
            {conversionRates.map((conversionRate) => {
                return (
                    <ConversionRateCard
                        conversionRate={conversionRate}
                        key={conversionRate.token}
                    />
                )
            })}
            {/* <AddConversionRate /> */}
        </div>
    )
}

function ConversionRateCard({ conversionRate }) {
    const [expanded, setExpanded] = useState(false)

    const [value_token_multiplier, setValue_token_multiplier] = useState(
        conversionRate.token_multiplier
    )
    const [value_minimum_claim_tokens, setValue_minimum_claim_tokens] =
        useState(conversionRate.minimum_claim_tokens)
    const [value_image, setValue_image] = useState(conversionRate.image)
    const [value_title, setValue_title] = useState(conversionRate.title)
    const [value_description, setValue_description] = useState(
        conversionRate.description
    )

    function handleSave() {
        let value_token_multiplier_number = value_token_multiplier
        if (!!value_token_multiplier_number) {
            value_token_multiplier_number = parseInt(value_token_multiplier)
            if (
                isNaN(value_token_multiplier_number) ||
                value_token_multiplier_number < 0
            )
                return Swal.fire({
                    title: 'Invalid token multiplier',
                    heightAuto: false,
                })
        }
        let value_minimum_claim_tokens_number = value_minimum_claim_tokens
        if (!!value_minimum_claim_tokens_number) {
            value_minimum_claim_tokens_number = parseInt(
                value_minimum_claim_tokens
            )
            if (
                isNaN(value_minimum_claim_tokens_number) ||
                value_minimum_claim_tokens_number < 0
            )
                return Swal.fire({
                    title: 'Invalid minimum tokens value',
                    heightAuto: false,
                })
        }

        let updateParams = {
            token: conversionRate.token,
            token_multiplier: value_token_multiplier_number || null,
            minimum_claim_tokens: value_minimum_claim_tokens_number || null,
            image: value_image || null,
            title: value_title || null,
            description: value_description || null,
        }

        confirmAction(updateConversionRate, updateParams)
    }

    return (
        <div>
            <div
                className="border border-white/30 bg-white/10 hover:bg-white/30 rounded-md cursor-pointer text-white px-4 py-1"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'Collapse' : 'Expand'} {conversionRate.token}
            </div>
            {expanded && (
                <div className="mt-1 border border-white/30 bg-white/2  rounded-md px-3 pb-3 pt-1.5 flex flex-col gap-2">
                    <div className="">
                        <p className="text-white mb-1">
                            Token exchange rate{' '}
                            <span className="text-neutral-400 text-xs ml-1">
                                {'(1: $1 = 1RFRM | 10: $1 = 10RFRM)'}
                            </span>
                        </p>
                        <Input
                            value={value_token_multiplier}
                            prefix={
                                <p className="text-nowrap text-neutral-400">
                                    $1 ={' '}
                                </p>
                            }
                            onChange={(e) =>
                                setValue_token_multiplier(e.target.value)
                            }
                            suffix={
                                <p className="text-nowrap text-neutral-400">
                                    {conversionRate.token}
                                </p>
                            }
                        ></Input>
                    </div>
                    <div className="">
                        <p className="text-white mb-1">Minimum Tokens</p>
                        <Input
                            value={value_minimum_claim_tokens}
                            onChange={(e) =>
                                setValue_minimum_claim_tokens(e.target.value)
                            }
                        ></Input>
                    </div>
                    <div className="">
                        <p className="text-white mb-1">Image</p>
                        <Input
                            value={value_image}
                            onChange={(e) => setValue_image(e.target.value)}
                        ></Input>
                    </div>
                    <div className="">
                        <p className="text-white mb-1">Title</p>
                        <Input
                            value={value_title}
                            onChange={(e) => setValue_title(e.target.value)}
                        ></Input>
                    </div>
                    <div className="">
                        <p className="text-white mb-1">Description</p>
                        <Input
                            value={value_description}
                            onChange={(e) =>
                                setValue_description(e.target.value)
                            }
                        ></Input>
                    </div>
                    <div className="flex justify-end mt-3 gap-3">
                        <Button
                            variant="border"
                            onClick={() => setExpanded(false)}
                        >
                            Close
                        </Button>
                        <Button className="w-[130px]" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

function AddConversionRate() {
    const [expanded, setExpanded] = useState(false)

    if (!expanded)
        return (
            <Button variant="border" onClick={() => setExpanded(true)}>
                <LuPlusCircle /> Add ConversionRate
            </Button>
        )

    return (
        <div className="">
            <p className="text-white">Token Name</p>
            <Input></Input>
            <Button variant="border">Create</Button>
        </div>
    )
}

function WarningCard({ setWarningSeen }) {
    return (
        <Card className={'h-full flex items-center justify-center '}>
            <div className="max-w-[500px] gap-3 flex flex-col items-center">
                <LuAlertTriangle className="text-orange-500 text-3xl mb-3" />
                <p className="-mb-1 text-white font-bold text-lg">Caution</p>
                <p className="text-neutral-400 text-center text-pretty">
                    Be extremely careful asjusting the conversion rates. These
                    are crucial for all task operations in the Quest Platform.
                    This dashboard is built for superadmins and is not
                    dummyproof as any change is permanent.
                    <br />
                    <br />
                    You can continue. Nothing happens still.
                </p>
                <div className="flex items-center justify-center mt-8">
                    <Button
                        variant="primary"
                        onClick={() => {
                            sessionStorage.setItem(
                                'reformdao-warning-seen-conversion-rates',
                                'seen'
                            )
                            setWarningSeen('seen')
                        }}
                    >
                        I understand, continue
                    </Button>
                </div>
            </div>
        </Card>
    )
}

function confirmAction(action, props) {
    Swal.fire({
        title: 'Are you sure?',
        text: 'This action is permanent and cannot be undone',
        icon: 'warning',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        heightAuto: false,
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            action(props)
        }
    })
}

async function getConversionRates(setConversionRates) {
    const config = {
        url: GET_SUPERADMIN_CONVERSION_RATES_URL,
        method: 'get',
        data: {},
    }

    Request(config)
        .then((response) => {
            const conversionRates = response?.conversionRates
            if (!conversionRates) return
            setConversionRates(conversionRates)
        })
        .catch((err) => {
            console.log(err)
        })
}

async function updateConversionRate(updateParams) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: UPDATE_SUPERADMIN_CONVERSION_RATES_URL,
        method: 'post',
        data: {
            updateParams: JSON.stringify(updateParams),
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Successfully updated',
                icon: 'success',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            })
        })
        .catch((err) => {
            console.log(err)
        })
}

function getXPToDollarRate(setXPToDollarRate) {
    const config = {
        url: GET_ADMIN_SETTING_URL,
        method: 'get',
        data: {
            key: XPDollarRateKey,
        },
    }

    Request(config)
        .then((response) => {
            const adminStat_value = response?.adminStat_value
            setXPToDollarRate(adminStat_value)
        })
        .catch((err) => {
            console.log(err)
        })
}

async function updateXPToDollarRate({ value }) {
    const { signature, wallet } = await getEthereumSignature()
    if (!signature)
        return swalError('No signature available. Sign in with metamask')

    const config = {
        url: UPDATE_ADMIN_SETTING_URL,
        method: 'post',
        data: {
            key: XPDollarRateKey,
            value: value,
            access_wallet: wallet,
            access_signature: signature,
        },
    }

    Request(config)
        .then((response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Successfully updated',
                icon: 'success',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                showCancelButton: false,
                allowOutsideClick: false,
                heightAuto: false,
            })
        })
        .catch((err) => {
            console.log(err)
        })
}
