import { create } from 'zustand'

export const useSelectedTaskCategoryState = create()((set) => ({
    selectedTaskCategory: null,
    setSelectedTaskCategory: (newVal) =>
        set((state) => ({ selectedTaskCategory: newVal })),
}))

export const useSelectedTaskState = create()((set) => ({
    selectedTask: null,
    setSelectedTask: (newVal) => set((state) => ({ selectedTask: newVal })),
}))
