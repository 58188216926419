const StakingType = {
    SSS: { type: 'sss', label: 'Single Side Staking' },
    BS: { type: 'bs', label: 'Bonding Staking' },
    LPS: { type: 'lps', label: 'Liquidity Pool Staking' },
    NFT: { type: 'nft', label: 'NFT Staking' },
}

const chains = {
    0: 'kardia',
    1: 'ethereum',
    5: 'goerli',
    8: 'ubiq',
    10: 'optimism',
    19: 'songbird',
    20: 'elastos',
    25: 'cronos',
    30: 'rsk',
    40: 'telos',
    52: 'csc',
    55: 'zyx',
    56: 'binance',
    57: 'syscoin',
    60: 'gochain',
    61: 'ethclassic',
    66: 'okexchain',
    70: 'hoo',
    82: 'meter',
    88: 'tomochain',
    100: 'xdai',
    106: 'velas',
    108: 'thundercore',
    122: 'fuse',
    128: 'heco',
    137: 'polygon',
    200: 'xdaiarb',
    246: 'energyweb',
    250: 'fantom',
    269: 'hpb',
    288: 'boba',
    321: 'kucoin',
    336: 'shiden',
    361: 'theta',
    534: 'candle',
    592: 'astar',
    820: 'callisto',
    888: 'wanchain',
    1088: 'metis',
    1284: 'moonbeam',
    1285: 'moonriver',
    2020: 'ronin',
    2612: 'ezchain',
    4689: 'iotex',
    5050: 'xlc',
    5551: 'nahmii',
    59140: 'linea Goerli',
    8217: 'klaytn',
    10000: 'smartbch',
    32659: 'fusion',
    42161: 'arbitrum',
    42220: 'celo',
    42262: 'oasis',
    43114: 'avalanche',
    71394: 'godwoken',
    333999: 'polis',
    11155111: 'sepolia',
    1313161554: 'aurora',
    1666600000: 'harmony',
    11297108109: 'palm',
    836542336838601: 'curio',
}

const ModalType = {
    TVL: 'tvl',
    TREASURY: 'treasury',
    TRADING_TREASURY: 'trading_treasury',
    TRADING_STATS: 'trading_stats',
    BONDS: 'bonds',
    PRICE_STATS: 'price_stats',
    SSS: 'sss',
    LPS: 'lps',
    STAKING_OVERVIEW_TABLE: 'staking_overview_table',
    CLAIMS_TABLE: 'claims_table',
    VOTING: 'voting',
    CIRCULATING_SUPPLY: 'circulating_supply',
    MARKET_CAP: 'market_cap',
    BONDING_SUPPLY: 'bonding_supply',
    STAKING_SUPPLY: 'staking_supply',
    RFRM_IN_TREASURY: 'RFRM_in_treasury',
}

export { chains, StakingType, ModalType }
