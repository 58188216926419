import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export default function TooltipContainer({
    children,
    message,
    position = 'top',
}) {
    const [uuid, setUuid] = useState(uuidv4())

    return (
        <>
            <span data-tooltip-id={uuid}>{children}</span>
            <ReactTooltip
                id={uuid}
                place={position}
                content={message}
                className=" !duration-0 !max-w-[250px] text-center !text-pretty !z-50"
            />
        </>
    )
}
