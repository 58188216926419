import React from 'react'
import novus from '../../../assets/svg/novus-logo.svg'

export default function QuestplatformHero({ title }) {
    return (
        <div className="w-full lg:h-[440px] lg:pb-0 pb-[40px] lg:px-0 px-4">
            <div className="w-full h-full flex flex-col justify-center pt-[40px]">
                <a href="/novus">
                    <img src={novus} alt="Novus logo" width={285} height={87} />
                </a>
                <h1 className="text-white lg:text-5xl text-3xl font-mono mt-3">
                    {title && <span className="text-primary">{title}</span>}
                </h1>
                <p className="text-neutral-400 text-sm max-w-[400px] w-full mt-3 font-sans">
                    Join now to engage, grow your online presence, and be
                    rewarded for your participation.
                </p>
            </div>
        </div>
    )
}
